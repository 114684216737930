import React, { Component, Suspense, lazy } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
// import LazyLoad from "react-lazyload";

// Get Files for Slider Start
import Slider from "../../slider";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
// Get Files for Slider End

// Get Images Start
import landingpage from "../../assets/images/Header/wesite-design.svg";
import responsive from "../../assets/images/Header/responsive.svg";
import logoDesign from "../../assets/images/Header/logo_design.svg";
import bannerDesign from "../../assets/images/Header/banner_design.svg";
import brochureDesign from "../../assets/images/Header/brochure_design.svg";
// import printmedia from "../../assets/images/Header/printmedia.svg";
import mobileAppIcon from "../../assets/images/Header/MobileAppIcon.svg";
import landingpage_White from "../../assets/images/white-icons/wesite-design.svg";
import responsive_White from "../../assets/images/white-icons/responsive.svg";
import logoDesign_White from "../../assets/images/white-icons/logo_design.svg";
import bannerDesign_White from "../../assets/images/white-icons/banner_design.svg";
import brochureDesign_White from "../../assets/images/white-icons/brochure_design.svg";
// import printmedia_White from "../../assets/images/white-icons/printmedia.svg";
import mobileAppIcon_White from "../../assets/images/white-icons/MobileAppIcon.svg";
// import meanStackAPIDevlp from "../../assets/images/MeanStackDevelopment/meanStackAPIDevlp.png";
import print_round from '../../assets/images/PrintMedia/print_round.png';
import print_circle_color from '../../assets/images/PrintMedia/print-circle-bottom-color.png';
import print_circle_top_color from '../../assets/images/PrintMedia/print-circle-top.png';
// import coffeeService from '../../assets/images/GraphicDesign/coffeeService.jpg';
import raf_gear from '../../assets/images/GraphicDesign/raf_gear.jpg';
import underCoverConsult from '../../assets/images/GraphicDesign/underCoverConsult.jpg';
import westernStore from '../../assets/images/GraphicDesign/westernStore.jpg';
import Alias_Bakery from '../../assets/images/GraphicDesign/Alias-Bakery.jpg';
// import Beach_Rentals from '../../assets/images/GraphicDesign/Beach-Rentals.jpg';
import Charitable_Planet from '../../assets/images/GraphicDesign/Charitable-Planet.jpg';
import Gorilla_vpn from '../../assets/images/GraphicDesign/Gorilla-vpn.jpg';
import Love from '../../assets/images/GraphicDesign/Love.jpg';
import Tissa_Innovation from '../../assets/images/GraphicDesign/Tissa-Innovation.jpg';
import Trainers_for_you from '../../assets/images/GraphicDesign/Trainers-for-you.jpg';
import Broucher1 from '../../assets/images/GraphicDesign/Broucher1.png';
import Broucher2 from '../../assets/images/GraphicDesign/Broucher2.png';
import Broucher3 from '../../assets/images/GraphicDesign/Broucher3.png';
import Broucher4 from '../../assets/images/GraphicDesign/Broucher4.png';
import lean_ipd from "../../assets/images/GraphicDesign/LEAN-IPD.jpg";
import CraftyCanineClub from "../../assets/images/GraphicDesign/CraftyCanineClub.jpg";
import gladev from "../../assets/images/GraphicDesign/gladev.png";
import sportsohio from "../../assets/images/GraphicDesign/sportsohio.jpg";
import Fluidgrids from "../../assets/images/UiUx/Fluidgrids.svg";
import Fluidimages from "../../assets/images/UiUx/Fluidimages.svg";
import Mediaqueries from "../../assets/images/UiUx/Mediaqueries.svg";
import CustomDevelopment from "../../assets/images/UiUx/CustomDevelopment.svg";
import DedicatedProjectManagers from "../../assets/images/UiUx/DedicatedProjectManagers.svg";
import Fullcommitment from "../../assets/images/UiUx/Fullcommitment.svg";
import Ai from "../../assets/images/UiUx/Ai.svg";
import Bannersuperiorquality from "../../assets/images/UiUx/Bannersuperiorquality.svg";
import Costeffectiveaffordable from "../../assets/images/UiUx/Costeffectiveaffordable.svg";
import major_networks_middle from "../../assets/images/UiUx/bannerdesign.png";
import Crispprecise from "../../assets/images/UiUx/Crispprecise.svg";
import Customerattention from "../../assets/images/UiUx/Customerattention.svg";
import Excitingsalescopies from "../../assets/images/UiUx/Excitingsalescopies.svg";
import GoogleAdsBanner from "../../assets/images/UiUx/GoogleAdsBanner.svg";
import indesign from "../../assets/images/UiUx/indesign.svg";
import Informativecontent from "../../assets/images/UiUx/Informativecontent.svg";
import MultipledesignBanner from "../../assets/images/UiUx/MultipledesignBanner.svg";
import Ourprintbanneraddesign from "../../assets/images/UiUx/Ourprintbanneraddesign.svg";
import Ourwebbanneraddesign from "../../assets/images/UiUx/Ourwebbanneraddesign.svg";
import photoshop from "../../assets/images/UiUx/photoshop.png";
import Staticwebsitebanner from "../../assets/images/UiUx/Staticwebsitebanner.svg";
import XD from "../../assets/images/UiUx/XD.svg";
import Consistentdesigns from "../../assets/images/UiUx/Consistentdesigns.svg";
import ProfessionalDesigners from "../../assets/images/UiUx/ProfessionalDesigners.svg";
import CustomLogoDesign from "../../assets/images/UiUx/CustomLogoDesign.svg";
import MemorableImpactful from "../../assets/images/UiUx/MemorableImpactful.svg";
import Multipleiterations from "../../assets/images/UiUx/Multipleiterations.svg";
import UI_UX from "../../assets/images/GraphicDesign/UI_UX.jpg";
import Armando_Personal_Injury_Law from "../../assets/images/GraphicDesign/Armando_Personal_Injury_Law.png";
import Christmas_Banner from "../../assets/images/GraphicDesign/Christmas_Banner.png";

import CustomAndroidAppDevelopment from "../../assets/images/ApplicationDevelopment/CustomAndroidAppDevelopment_white.svg";
import EnterpriseAndroidAppDevelopment from "../../assets/images/ApplicationDevelopment/EnterpriseAndroidAppDevelopment_white.svg";
import HybridAppDevelopment from "../../assets/images/ApplicationDevelopment/HybridAppDevelopment_white.svg";
import NativeAndroidAppDevelopment from "../../assets/images/ApplicationDevelopment/NativeAndroidAppDevelopment_white.svg";
import its_coller from "../../assets/images/ApplicationDevelopment/its-coller.png";
import Ugame from "../../assets/images/ApplicationDevelopment/Ugame.png";
import madd from "../../assets/images/ApplicationDevelopment/madd.png";
import rolela from "../../assets/images/ApplicationDevelopment/rolela.png";
import pullme from "../../assets/images/ApplicationDevelopment/pullme.png";
import mapmysound from "../../assets/images/ApplicationDevelopment/mapmysound.png";
import WorkoutMemo from "../../assets/images/ApplicationDevelopment/WorkoutMemo.png";
import pill_identification from "../../assets/images/ApplicationDevelopment/pill-identification.png";
import Prime from "../../assets/images/ApplicationDevelopment/Prime.png";
import gorilla_vpn from "../../assets/images/ApplicationDevelopment/gorilla_vpn.png";
import loopleap from "../../assets/images/ApplicationDevelopment/loopleap.png";
import luveCyprus from "../../assets/images/ApplicationDevelopment/luveCyprus.png";
import richness from "../../assets/images/ApplicationDevelopment/richness.png";
import tk_inspection from "../../assets/images/ApplicationDevelopment/tk_inspection.png";
import warp from "../../assets/images/ApplicationDevelopment/warp.png";
import Would_Love_To_Go from "../../assets/images/ApplicationDevelopment/Would-Love-To-Go.png";
import EpicPlace from "../../assets/images/ApplicationDevelopment/EpicPlace.png";
import scan_it from "../../assets/images/ApplicationDevelopment/scan_it.png";
import timeout from "../../assets/images/ApplicationDevelopment/timeout.png";
import php_icon from "../../assets/images/Portfolios/Technologies/php_icon.svg";
import wordpress_icon from "../../assets/images/Portfolios/Technologies/wordpress_icon.svg";
import bootstrap_icon from "../../assets/images/Portfolios/Technologies/bootstrap_icon.jpg";
import wooCommerce_icon from "../../assets/images/Portfolios/Technologies/wooCommerce_icon.svg";
// Get Images End   

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
// import NumberCount from "../common/NumberCount";
import SliderServiceCatPagePortfolio2 from "../common/SliderServiceCatPagePortfolio2";
import SliderServiceCatPagePortfolio3 from "../common/SliderServiceCatPagePortfolio3";
import SliderServiceCatPagePortfolio4 from "../common/SliderServiceCatPagePortfolio4";
const NumberCount = lazy(() => import("../common/NumberCount"));
// Get Common Components End

class UiUxDesign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "UI/UX Design",
            bannerImg: UI_UX,
            activeTab: "website-redesign",
            activeTab1: "tab-1",
            tabToggle: false,
            isClickedEventFired: 0,
            websiteRedesignSlider: [],
            mobileAppSlider: []
        };
        smoothscroll.polyfill();
        this.baseState = this.state;
    }
    componentWillMount() {
        let isAuthenticated = !localStorage.getItem("isAuthenticated");
        if (!isAuthenticated) {
            this.props.history.push("/login");
            window.location.reload();
        }

        const websiteRedesignData = [
            {
                image: lean_ipd,
                title: "Lean IPD",
                link: "https://leanipd.com/",
                tech_images: [{ icon: php_icon, title: "PHP" }, { icon: wordpress_icon, title: "Wordpress" }, { icon: wooCommerce_icon, title: "WooCommerce" }]
            },
            {
                image: CraftyCanineClub,
                title: "CraftyCanineClub",
                link: "https://craftycanineclub.com/",
                tech_images: [{ icon: php_icon, title: "PHP" }, { icon: wordpress_icon, title: "Wordpress" }]
            },
            {
                image: gladev,
                title: "GLADEV",
                link: "https://www.gladev.com/onepage",
                tech_images: [{ icon: php_icon, title: "PHP" }, { icon: wordpress_icon, title: "Wordpress" }]
            },
            // {
            //     image: sportsohio,
            //     title: "Sports Ohio",
            //     link: "http://sportsohio.org/",
            //     tech_images: [{ icon: php_icon, title: "PHP" }, { icon: wordpress_icon, title: "Wordpress" }, { icon: bootstrap_icon, title: "Bootstrap" }]
            // }
        ];

        const mobileAppData = [
            // {
            //     image: Ugame,
            //     title: "Ugame",
            //     iOSBtnLink: "https://apps.apple.com/st/app/ugame/id1186722645",
            //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.grappetite.ugame&hl=en",
            //     tech_images: []
            // },
            // {
            //     image: madd,
            //     title: "Madd Loans",
            //     iOSBtnLink: "",
            //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.maddloans",
            //     tech_images: []
            // },
            // {
            //     image: its_coller,
            //     title: "iTs Cooler",
            //     iOSBtnLink: "",
            //     androidBtnLink: "",
            //     tech_images: []
            // },
            // {
            //     image: rolela,
            //     title: "Rolela",
            //     iOSBtnLink: "https://apps.apple.com/us/app/rolela/id1439787970?ls=1",
            //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.rolela&hl=en",
            //     tech_images: []
            // },
            // {
            //     image: Would_Love_To_Go,
            //     title: "Would Love To Go",
            //     iOSBtnLink: "",
            //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.wltg.app&hl=en",
            //     tech_images: []
            // },
            {
                image: mapmysound,
                title: "MapMySound",
                iOSBtnLink: "https://apps.apple.com/us/app/mapmysound/id1370044887?ls=1",
                androidBtnLink: "",
                tech_images: []
            },
            {
                image: WorkoutMemo,
                title: "WorkoutMemo",
                iOSBtnLink: "",
                androidBtnLink: "",
                tech_images: []
            },
            // {
            //     image: pill_identification,
            //     title: "Pill Identification",
            //     iOSBtnLink: "https://apps.apple.com/us/app/pill-identification/id1046763249",
            //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.pillidapp",
            //     tech_images: []
            // },
            {
                image: Prime,
                title: "Prime",
                iOSBtnLink: "",
                androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.priMe",
                tech_images: []
            },
            // {
            //     image: EpicPlace,
            //     title: "EpicPlace",
            //     iOSBtnLink: "https://apps.apple.com/us/app/epicplace/id1028804394",
            //     androidBtnLink: "",
            //     tech_images: []
            // },
            // {
            //     image: pullme,
            //     title: "PullMe",
            //     iOSBtnLink: "https://apps.apple.com/in/app/pullme/id1473117105",
            //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.pullme.customer",
            //     tech_images: []
            // },
            // {
            //     image: gorilla_vpn,
            //     title: "Gorilla VPN",
            //     iOSBtnLink: "",
            //     androidBtnLink: "",
            //     tech_images: []
            // },
            // {
            //     image: loopleap,
            //     title: "Loopleap",
            //     iOSBtnLink: "https://apps.apple.com/us/app/loopleap/id1451157270",
            //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.loopleap",
            //     tech_images: []
            // },
            // {
            //     image: luveCyprus,
            //     title: "Luv Cyprus",
            //     iOSBtnLink: "",
            //     androidBtnLink: "",
            //     tech_images: []
            // },
            // {
            //     image: richness,
            //     title: "RichNess",
            //     iOSBtnLink: "",
            //     androidBtnLink: "https://play.google.com/store/apps/details?id=richness.com.richness",
            //     tech_images: []
            // },
            // {
            //     image: tk_inspection,
            //     title: "TK Inspection",
            //     iOSBtnLink: "",
            //     androidBtnLink: "",
            //     tech_images: []
            // },
            // {
            //     image: warp,
            //     title: "Warp",
            //     iOSBtnLink: "",
            //     androidBtnLink: "",
            //     tech_images: []
            // },
            // {
            //     image: scan_it,
            //     title: "Scan It",
            //     iOSBtnLink: "",
            //     androidBtnLink: "",
            //     tech_images: []
            // },
            {
                image: timeout,
                title: "TimeOut",
                iOSBtnLink: "",
                androidBtnLink: "",
                tech_images: []
            }
        ];

        this.setState({ websiteRedesignSlider: websiteRedesignData, mobileAppSlider: mobileAppData });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listenScrollEvent, false);
    }

    listenScrollEvent = e => {
        if (this.state.isClickedEventFired > 0) {
            var abc = setTimeout(() => {
                this.setState({ isClickedEventFired: 0 });
            }, 5000);
            if (this.state.isClickedEventFired > 1) {
                clearTimeout(abc);
            }
        } else {
            var section1 = this.checkElementInViewport(
                document.getElementById("website-redesign")
            );
            var section2 = this.checkElementInViewport(
                document.getElementById("responsive-web-design")
            );
            var section3 = this.checkElementInViewport(
                document.getElementById("logo-designing")
            );
            var section4 = this.checkElementInViewport(
                document.getElementById("banner-designing")
            );
            var section5 = this.checkElementInViewport(
                document.getElementById("brochure-designing")
            );
            var section6 = this.checkElementInViewport(
                document.getElementById("mobile-app-design")
            );

            if (section1 && section2) {
                this.setState({ activeTab: "website-redesign", activeTab1: "tab-1" });
            }
            if ((section2 && !section1) || (section2 && section3)) {
                this.setState({
                    activeTab: "responsive-web-design",
                    activeTab1: "tab-2"
                });
            }
            if ((section3 && !section2) || (section3 && section4)) {
                this.setState({
                    activeTab: "logo-designing",
                    activeTab1: "tab-3"
                });
            }
            if ((section4 && !section3) || (section4 && section5)) {
                this.setState({
                    activeTab: "banner-designing",
                    activeTab1: "tab-4"
                });
            }
            if ((section5 && !section4) || (section5 && section6)) {
                this.setState({
                    activeTab: "brochure-designing",
                    activeTab1: "tab-5"
                });
            }
            if (section6 && !section5) {
                this.setState({ activeTab: "mobile-app-design", activeTab1: "tab-6" });
            }
        }
    };

    checkElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;
        const windowWidth =
            window.innerWidth || document.documentElement.clientWidth;

        const vertInView =
            rect.top <= windowHeight && rect.top-74 + rect.height >= 0;
        const horInView =
            rect.left <= windowWidth && rect.left-74 + rect.width >= 0;

        return vertInView && horInView;
    }

    handleOnClickScroll = (divId, tab) => {
        this.setState({
            activeTab: divId,
            activeTab1: tab,
            isClickedEventFired: this.state.isClickedEventFired + 1
        });
        var ref = document.getElementById(divId);
        window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
    };

    handleTabToggle = () => {
        this.setState(state => ({ tabToggle: !state.tabToggle }));
    };

    componentWillReceiveProps(nextProps) {
        var backToTop = localStorage.getItem("backToTop");
        if (nextProps.match.params.id) {
            if (nextProps.match.params.id !== this.props.match.params.id) {
                var ref = document.getElementById(nextProps.match.params.id);
                window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
            }
        }
        if (backToTop && backToTop === "true") {
          this.setState({
            activeTab: this.baseState.activeTab,
            activeTab1: this.baseState.activeTab1
          });
          localStorage.removeItem("backToTop");
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.listenScrollEvent, false);
        if (this.props.match.params.id) {
            var ref = document.getElementById(this.props.match.params.id);
            if (ref)
                setTimeout(() => {
                    window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
                }, 500);
            else
                this.props.history.push("/services/uiux-design-services");
        }
    }

    getExperienceyear() {
        var today = new Date();
        var start_date = new Date("june 02, 1993");
        var years_now = today.getFullYear() - start_date.getFullYear();
        var m = today.getMonth() - start_date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) {
            years_now--;
        }
        return years_now;
    }

    render() {
        if (this.props.match.params.id) {
            if (this.props.match.params.id === 'website-redesign') {
                document.title = "Website Redesign Services in India | Website Redesigning";
            } else if (this.props.match.params.id === 'responsive-web-design') {
                document.title = "Responsive Web Design Services | Responsive Design Company";
            } else if (this.props.match.params.id === 'logo-designing') {
                document.title = "Logo Designing Services | Company Logo Design India";
            } else if (this.props.match.params.id === 'banner-designing') {
                document.title = "Web Banner Design Services | Banner Design Company India";
            } else if (this.props.match.params.id === 'brochure-designing') {
                document.title = "Brochure Design Company India | Brochure Design Services";
            } else if (this.props.match.params.id === 'print-media-design') {
                document.title = "Print Media Design Services | Print Ad Design Company India";
            } else if (this.props.match.params.id === 'mobile-app-design') {
                document.title = "Mobile App Design Company India | App Designers";
            } else {
                document.title = "UI UX Design Company | User Experience Design Services";
            }
        } else {
            document.title = "UI UX Design Company | User Experience Design Services";
        }
        const { pageTitle, bannerImg, websiteRedesignSlider, mobileAppSlider } = this.state;

        const settingsLogoSlider = {
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            rows: 2,
            responsive: [
                {
                    breakpoint: 991.98,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 767.98,
                    settings: {
                        slidesToShow: 3,
                        rows: 2,
                    }
                },
                {
                    breakpoint: 599.98,
                    settings: {
                        rows: 1,
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: "60px"

                    }
                }
            ]
        };

        const settingsBroucherSlider = {
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            centerMode: true,
            centerPadding: "0px",
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: false,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false
                    }
                }
            ]
        };
        const settingsBannerDesigningSlider = {
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const settingsPrinciplesListing = {
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1279.98,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767.98,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };

        return (
            <div className="main_graphicDesign service_category main_services">
                <Grid container>
                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}

                    {/* Start - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}
                    <Grid item xs={12}>
                        <Grid component="div">
                            <Grid className="serivceTabsSide">
                                <Grid className={`services-listing ${this.state.tabToggle ? "showTab" : ""}`}>
                                    <div className="toggle-btn" onClick={this.handleTabToggle}>
                                        <i className="material-icons">arrow_forward</i>
                                    </div>
                                    <Grid item container className="container_custom">
                                        <Grid item xs={12}>
                                            <div className={`row-flex ${this.state.activeTab1}`}>
                                                <div className={`col-flex tab-1 ${this.state.activeTab === "website-redesign" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("website-redesign", "tab-1")} title="Website Redesign">
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={landingpage} className="withoutActive" alt="Website Redesign" title="Website Redesign" />
                                                            <img width="30" height="30" src={landingpage_White} className="withActive" alt="Website Redesign" title="Website Redesign" />
                                                        </div>
                                                        <Typography>
                                                            Website
                                                    <br /> Redesign{" "}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className={`col-flex tab-2 ${this.state.activeTab === "responsive-web-design" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("responsive-web-design", "tab-2")} title="Responsive Web Design">
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={responsive} className="withoutActive" alt="Responsive Web Design" title="Responsive Web Design" />
                                                            <img width="30" height="30" src={responsive_White} className="withActive" alt="Responsive Web Design" title="Responsive Web Design" />
                                                        </div>
                                                        <Typography>
                                                            Responsive Web
                                                    <br /> Design
                                                </Typography>
                                                    </div>
                                                </div>
                                                <div className={`col-flex tab-3 ${this.state.activeTab === "logo-designing" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("logo-designing", "tab-3")} title="Logo Designing">
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={logoDesign} className="withoutActive" alt="Logo Designing" title="Logo Designing" />
                                                            <img width="30" height="30" src={logoDesign_White} className="withActive" alt="Logo Designing" title="Logo Designing" />
                                                        </div>
                                                        <Typography>
                                                            Logo
                                                    <br /> Designing
                                                </Typography>
                                                    </div>
                                                </div>
                                                <div className={`col-flex tab-4 ${this.state.activeTab === "banner-designing" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("banner-designing", "tab-4")} title="Banner Designing">
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={bannerDesign} className="withoutActive" alt="Banner Designing" title="Banner Designing" />
                                                            <img width="30" height="30" src={bannerDesign_White} className="withActive" alt="Banner Designing" title="Banner Designing" />
                                                        </div>
                                                        <Typography>
                                                            Banner
                                                    <br /> Designing
                                                </Typography>
                                                    </div>
                                                </div>
                                                <div className={`col-flex tab-5 ${this.state.activeTab === "brochure-designing" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("brochure-designing", "tab-5")} title="Brochure Designing" >
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={brochureDesign} className="withoutActive" alt="Brochure Designing" title="Brochure Designing" />
                                                            <img width="30" height="30" src={brochureDesign_White} className="withActive" alt="Brochure Designing" title="Brochure Designing" />
                                                        </div>
                                                        <Typography>
                                                            Brochure
                                                    <br /> Designing
                                                </Typography>
                                                    </div>
                                                </div>
                                                <div className={`col-flex tab-6 ${this.state.activeTab === "mobile-app-design" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("mobile-app-design", "tab-6")} title="Mobile App Design">
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={mobileAppIcon} className="withoutActive" alt="Mobile App Design" title="Mobile App Design" />
                                                            <img width="30" height="30" src={mobileAppIcon_White} className="withActive" alt="Mobile App Design" title="Mobile App Design" />
                                                        </div>
                                                        <Typography>
                                                            Mobile App
                                                    <br /> Design
                                                </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}

                    {/* Start - Section Design "First Section Of Service Category Page" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="sec_padding sec_one">
                            <Grid item container className="container_custom">
                                <Grid item xs={12} className="title_row">
                                    <aside className="">
                                        <Typography variant="h3" className="head_title mb-3" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
                                            With our Design Thinking technology, rich experience,<br /> and a deep understanding of the industry.
                                </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1500">
                                            No matter how powerful or innovative your application idea is, without a solid well thought user interface design it won’t achieve success easily. In today’s competitive world only a good user experience will define the future of the app. We are a <strong>leading UI/UX Design company</strong>. We understand the power of user experience engineering and bring our <strong>{this.getExperienceyear()}+ years</strong> of web and application design experience into play to provide you specially crafted <strong>UI/UX design</strong> services that can help your idea stand out.
                                </Typography>
                                    </aside>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "First Section Of Service Category Page" */}

                    {/* Start - Section Design "Website Redesign" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits websiteRedesignSec section_title_space bg-lightgrey" id="website-redesign">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Website Redesign
                                        </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            We will help you redesign your present website. Got a feeling that your website does not look good among the sophisticated and futuristic websites nowadays swarming the digital world? We’re here to help you transform yours too. Join us today and redesign your future.
                                        </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1000">
                                        <img alt="Custom Development" title="Custom Development" src={CustomDevelopment} />
                                        <Typography variant="h4" className="head_title">
                                            Custom Development
                                    </Typography>
                                        <Typography className="head_paragraph">
                                            We work in close-coordination with the customers, to ensure flexible & cost effective web application development services. Our <strong>web designers</strong> after the thorough analysis of your business, will come up with a customized web design and development solution.
                                    </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1400">
                                        <img alt="Dedicated Project Managers" title="Dedicated Project Managers" src={DedicatedProjectManagers} />
                                        <Typography variant="h4" className="head_title">
                                            Dedicated Project Managers
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Our developers, designers and testers are well aware of their craft and are managed by experienced project managers. This team will make sure that you get your project before the deadline.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1800">
                                        <img alt="Full Commitment" title="Full Commitment" src={Fullcommitment} />
                                        <Typography variant="h4" className="head_title">
                                            Full Commitment
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Majority of our business originates from referrals. We will strive to get another satisfied client and we trust that you will remain with us for a long period of time – in the same way as majority of our customers do.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="2200">
                                        <img alt="Professional Corporate" title="Consistent designs" src={Consistentdesigns} />
                                        <Typography variant="h4" className="head_title">
                                            Consistent designs
                                </Typography>
                                        <Typography className="head_paragraph">
                                            The right approach to build trust with your users is to keep your designs consistent throughout the site, which can be done by maintaining the same navigation, menu bar, same color schemas and fonts.
                              </Typography>
                                    </div>
                                </Grid>
                                <SliderServiceCatPagePortfolio2 data={websiteRedesignSlider} />
                                <Grid item xs={12} className="btn-wrap" data-aos="fade-up" data-aos-duration="1000">
                                    <Link to="/hire-developers/hire-web-designer" className="dark-btn-effect all-category-mobile" title="Hire Web Designer">
                                        Hire Web Designer
                            </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Website Redesign" */}

                    {/* Start - Section Design "Responsive Web Design" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="responsive-webDesign sec_padding responisveWebDesignSec section_title_space" id="responsive-web-design">
                            <Grid item container className="container_custom">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title mb-3" data-aos="fade-up" data-aos-duration="800">
                                            Responsive Web Design
                                        </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            Only <strong>web designs</strong> that respond to any size device will receive Google’s badge of mobile-friendliness that pushes them up in search rankings. Our <strong>responsive web designs</strong> create a user-focused and search engine optimized experience for your target audience when they visit your site with any device.
                                        </Typography>
                                    </aside>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="three-box-shape">
                                        <ul className="leftSide">
                                            <li className="yellow" data-aos="fade-right" data-aos-duration="400">
                                                <aside>
                                                    <div className="text-wrap">
                                                        <Typography variant="h6" className="text-right">
                                                            Fluid grids
                                                </Typography>
                                                        <Typography className="head_paragraph text-right">
                                                            A flexible grid system that changes your website’s width and margins to match visitors' mobile screens.
                                                </Typography>
                                                    </div>
                                                    <div className="icon-wrap">
                                                        <img alt="Fluid Grids" title="Fluid Grids" src={Fluidgrids} />
                                                    </div>
                                                </aside>
                                            </li>
                                        </ul>
                                        <ul className="rightSide">
                                            <li className="red" data-aos="fade-left" data-aos-duration="400">
                                                <aside>
                                                    <div className="icon-wrap">
                                                        <img alt="Fluid Images" title="Fluid Images" src={Fluidimages} />
                                                    </div>
                                                    <div className="text-wrap">
                                                        <Typography variant="h6" className="">
                                                            Fluid images
                                                </Typography>
                                                        <Typography className="head_paragraph text-left">
                                                            Coding that adjusts all your website's images to the size of any smart device so your images never look stretched or pixelated.
                                                </Typography>
                                                    </div>
                                                </aside>
                                            </li>
                                            <li className="blue" data-aos="fade-up" data-aos-duration="400">
                                                <aside>
                                                    <div className="icon-wrap">
                                                        <img alt="Media Queries" title="Media Queries" src={Mediaqueries} />
                                                    </div>
                                                    <div className="text-wrap">
                                                        <Typography variant="h6" className="">
                                                            Media queries
                                                </Typography>
                                                        <Typography className="head_paragraph text-left">
                                                            <strong>CSS technology</strong> that rearranges your site's content to fit onto any smart device screen size. No content will be left out!
                                                </Typography>
                                                    </div>
                                                </aside>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <SliderServiceCatPagePortfolio4 />
                                </Grid>
                                <Grid item xs={12} className="btn-wrap" data-aos="fade-up" data-aos-duration="1000">
                                    <Link to="/hire-developers/hire-web-designer" className="dark-btn-effect all-category-mobile" title="Hire Web Designer">
                                        Hire Web Designer
                            </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Responsive Web Design" */}

                    {/* Start - Section Design "Logo Designing" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding logoDesignSec bottomkeybenefits logoDesignSec section_title_space bg-lightgrey" id="logo-designing">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Logo Designing
                            </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            <strong>Visions</strong> has been designing unique and custom logos for past <strong>{this.getExperienceyear()}+ years</strong> which has led to a growing client base. Create an individual identity of your business and select from a wide range of services such as <strong>Logo Design</strong>.
                            </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1000">
                                        <img alt="Professional Designers" title="Professional Designers" src={ProfessionalDesigners} />
                                        <Typography variant="h4" className="head_title">
                                            Professional Designers
                            </Typography>
                                        <Typography className="head_paragraph">
                                            Our team consists of talented and experienced <strong>logo designers</strong> who share a passion for designing excellent quality logos.
                            </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1400">
                                        <img alt="Custom Logo Design" title="Custom Logo Design" src={CustomLogoDesign} />
                                        <Typography variant="h4" className="head_title">
                                            Custom Logo Design
                            </Typography>
                                        <Typography className="head_paragraph">
                                            A logo is the most important intangible asset of your company. Once designed, it becomes your company’s identity.
                            </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1800">
                                        <img alt="Memorable / Impactful" title="Memorable / Impactful" src={MemorableImpactful} />
                                        <Typography variant="h4" className="head_title">
                                            Memorable / Impactful
                            </Typography>
                                        <Typography className="head_paragraph">
                                            A great logo should be impactful. You want to capture your viewer’s attentions and leave a positive  impression.
                            </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="2200">
                                        <img alt="Multiple Iterations" title="Multiple Iterations" src={Multipleiterations} />
                                        <Typography variant="h4" className="head_title">
                                            Multiple iterations
                            </Typography>
                                        <Typography className="head_paragraph">
                                            A great logo should be relevant to your practice. It has to have meaning that relates to the work you are doing. And to ensure that, we will provide you multiple iterations to choose from.
                            </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="logoSlider" data-aos="fade-up" data-aos-duration="400">
                                        <Slider {...settingsLogoSlider}>
                                            {/* <div>
                                        <aside>
                                            <div className="logo_img ">
                                                <img src={coffeeService} alt="Coffee Service" title="Coffee Service" />
                                            </div>
                                        </aside>
                                    </div> */}
                                            <div>
                                                <aside>
                                                    <div className="logo_img ">
                                                        <img width="450" height="auto" src={raf_gear} alt="Raf Gear" title="Raf Gear" />
                                                    </div>
                                                </aside>
                                            </div>
                                            <div>
                                                <aside>
                                                    <div className="logo_img ">
                                                        <img width="450" height="auto" src={underCoverConsult} alt="Under Cover Consultant" title="Under Cover Consultant" />
                                                    </div>
                                                </aside>
                                            </div>
                                            <div>
                                                <aside>
                                                    <div className="logo_img ">
                                                        <img width="450" height="auto" src={westernStore} alt="Western Store" title="Western Store" />
                                                    </div>
                                                </aside>
                                            </div>
                                            <div>
                                                <aside>
                                                    <div className="logo_img ">
                                                        <img width="450" height="auto" src={Alias_Bakery} alt="Alia's Bakery & Consultant" title="Alia's Bakery & Consultant" />
                                                    </div>
                                                </aside>
                                            </div>
                                            {/* <div>
                                        <aside>
                                            <div className="logo_img ">
                                                <img src={Beach_Rentals} alt="Beach Rentals" title="Beach Rentals" />
                                            </div>
                                        </aside>
                                    </div> */}
                                            <div>
                                                <aside>
                                                    <div className="logo_img ">
                                                        <img width="450" height="auto" src={Charitable_Planet} alt="Charitable Planets" title="Charitable Planets" />
                                                    </div>
                                                </aside>
                                            </div>
                                            {/* <div>
                                                <aside>
                                                    <div className="logo_img ">
                                                        <img width="450" height="auto" src={Gorilla_vpn} alt="Gorilla VPN" title="Gorilla VPN" />
                                                    </div>
                                                </aside>
                                            </div> */}
                                            <div>
                                                <aside>
                                                    <div className="logo_img ">
                                                        <img width="450" height="auto" src={Love} alt="Love" title="Love" />
                                                    </div>
                                                </aside>
                                            </div>
                                            <div>
                                                <aside>
                                                    <div className="logo_img ">
                                                        <img width="450" height="auto" src={Tissa_Innovation} alt="Tissa Innovation" title="Tissa Innovation" />
                                                    </div>
                                                </aside>
                                            </div>
                                            <div>
                                                <aside>
                                                    <div className="logo_img ">
                                                        <img width="450" height="auto" src={Trainers_for_you} alt="Trainers For You" title="Trainers For You" />
                                                    </div>
                                                </aside>
                                            </div>
                                        </Slider>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Logo Designing" */}

                    {/* Start - Section Design "Banner Designing" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="major_social_networks sec_padding bannerDesignSec section_title_space" id="banner-designing">
                            <Grid item container className="container_custom">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title mb-3" data-aos="fade-up" data-aos-duration="800">
                                            Banner Designing
                                        </Typography>
                                        <Typography className="head_paragraph " data-aos="fade-up" data-aos-duration="1200">
                                            <strong>Banner</strong> is a very important part of an organization, company or brand. You can create ads and can drive good traffic to your website with the help of a unique and good banner. We have everything that would help you to easily turn <br />your ideas into <strong>creative designs</strong>.
                                        </Typography>
                                    </aside>
                                </Grid>
                                <Grid item container xs={12} className="major_networks_wrap">
                                    <Grid item xs={12} lg={4} className="aside">
                                        <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                            <img className="icon" src={Ourprintbanneraddesign} alt="Our Print Banner Ad Design" title="Our Print Banner Ad Design" />
                                            <Typography variant="h5" className="mb-1">
                                                Our print banner ad design
                                    </Typography>
                                            <Typography className="head_paragraph">
                                                <strong>Print banners</strong> we create are attractive and they can give wonderful mileage for your event promotions.
                                    </Typography>
                                        </div>
                                        <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                            <img className="icon" src={Ourwebbanneraddesign} alt="Our Web Banner Ad Design" title="Our Web Banner Ad Design" />
                                            <Typography variant="h5" className="mb-1">
                                                Our web banner ad design
                                    </Typography>
                                            <Typography className="head_paragraph">
                                                <strong>Professional web banners</strong> designed by us can propel your business to wider dimensions and help you penetrate into toughest marketplaces.
                                    </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="100">
                                            <img className="icon" src={GoogleAdsBanner} alt="Google Ads Banner" title="Google Ads Banner" />
                                            <Typography variant="h5" className="mb-1">
                                                Google Ads Banner
                                    </Typography>
                                            <Typography className="head_paragraph">
                                                <strong>Google AdWords</strong> allows you to create both text and banner advertising campaigns that are displayed next to Google search results and on websites.
                                    </Typography>
                                        </div>
                                        <div className="content_box middle_img">
                                            <img width="auto" height="auto" src={major_networks_middle} alt="Banner Designing" title="Banner Designing" />
                                        </div>
                                        <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="400">
                                            <img className="icon" src={Staticwebsitebanner} alt="Static Website Banner" title="Static Website Banner" />
                                            <Typography variant="h5" className="mb-1">
                                                Static website banner
                                    </Typography>
                                            <Typography className="head_paragraph">
                                                We can provide the best <strong>banner design services</strong> at affordable costs. We are backed up by a team of <strong>expert banner designers</strong>.
                                    </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={4} className="aside">
                                        <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                            <img className="icon" src={Bannersuperiorquality} alt="Banner superior quality" title="Banner superior quality" />
                                            <Typography variant="h5" className="mb-1">
                                                Banner superior quality
                                    </Typography>
                                            <Typography className="head_paragraph">
                                                Making use of the latest tools and technologies, we deliver superior quality banner designs as per the clients.
                                    </Typography>
                                        </div>
                                        <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                            <img className="icon" src={MultipledesignBanner} alt="Multiple Design Banner" title="Multiple Design Banner" />
                                            <Typography variant="h5" className="mb-1">
                                                Multiple design Banner
                                    </Typography>
                                            <Typography className="head_paragraph">
                                                We are a trusted choice of many clients because we offer multiple design options to choose from. Once you choose us for banner design services, we can deliver your work which can actually turn your readers into customers.
                                    </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="bannerDesigningSlider" data-aos="fade-up" data-aos-duration="400">
                                        <Slider {...settingsBannerDesigningSlider}>
                                            <div>
                                                <aside>
                                                    <div className="img_wrap">
                                                        <img width="646" height="auto" src={Armando_Personal_Injury_Law} alt="Armando Personal Injury Law" title="Armando Personal Injury Law" />
                                                    </div>
                                                </aside>
                                            </div>
                                            <div>
                                                <aside>
                                                    <div className="img_wrap">
                                                        <img width="646" height="auto" src={Christmas_Banner} alt="Christmas Banner" title="Christmas Banner" />
                                                    </div>
                                                </aside>
                                            </div>
                                        </Slider>
                                    </div>     
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Banner Designing" */}

                    {/* Start - Section Design "Brochure Designing" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding pb-0 bottomkeybenefits broucherDesignSec section_title_space bg-lightgrey" id="brochure-designing">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Brochure designing
                                </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            Our target is to drive you ahead of your competitors. Whether you need a mailer, a <strong>flyer design, tri-fold brochure, sales brochure, marketing brochure, training manual, booklet, pocket folder</strong>, or a catalog we have the expertise to deliver it.</Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1600">
                                            <strong>
                                                Here are our top favorite tools for designing brochures
                                    </strong>
                                        </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1000">
                                        <img alt="Adobe InDesign CC" title="Adobe InDesign CC" src={indesign} />
                                        <Typography variant="h4" className="head_title">
                                            <strong>Adobe InDesign CC</strong>
                                        </Typography>
                                        <Typography className="head_paragraph">
                                            Designing multi-page documents is what InDesign was built for, and it is very good at it. In addition, InDesign is part of the Adobe Creative Cloud software suite, which is considered the industry standard.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1400">
                                        <img alt="Adobe Illustrator CC" title="Adobe Illustrator CC" src={Ai} />
                                        <Typography variant="h4" className="head_title">
                                            <strong>Adobe Illustrator CC</strong>
                                        </Typography>
                                        <Typography className="head_paragraph">
                                            Illustrator has many of the same strengths and weaknesses as InDesign, but with a slightly different workflow. Illustrator is intended for professional designers who want to create high-end vector art.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1800">
                                        <img alt="Adobe Photoshop CC" title="Adobe Photoshop CC" src={photoshop} />
                                        <Typography variant="h4" className="head_title">
                                            <strong>Adobe Photoshop CC</strong>
                                        </Typography>
                                        <Typography className="head_paragraph">
                                            Brochures, pamphlets and product pages are useful ways of showcasing products, services or ideas. Brochures can also offer a good platform for a coupon, special offer or business card to drive further engagement.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="2200">
                                        <img alt="Adobe XD" title="Adobe XD" src={XD} />
                                        <Typography variant="h4" className="head_title">
                                            <strong>Adobe XD</strong>
                                        </Typography>
                                        <Typography className="head_paragraph">
                                            With Responsive Resize enabled, XD helps determine how elements should scale and align in the design. You can also use a combination of the automatic and manual settings to resize and pin objects in your brochure design.
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="broucherSlider" data-aos="fade-up" data-aos-duration="400">
                                        <Slider {...settingsBroucherSlider}>
                                            <div>
                                                <aside>
                                                    <div className="broucher_img">
                                                        <img width="646" height="auto" src={Broucher1} alt="ECO Sanitise" title="ECO Sanitise" />
                                                    </div>
                                                </aside>
                                            </div>
                                            <div>
                                                <aside>
                                                    <div className="broucher_img">
                                                        <img width="646" height="auto" src={Broucher2} alt="Eat Menu" title="Eat Menu" />
                                                    </div>
                                                </aside>
                                            </div>
                                            <div>
                                                <aside>
                                                    <div className="broucher_img">
                                                        <img width="646" height="auto" src={Broucher3} alt="Enrollee's Guide" title="Enrollee's Guide" />
                                                    </div>
                                                </aside>
                                            </div>
                                            <div>
                                                <aside>
                                                    <div className="broucher_img">
                                                        <img width="646" height="auto" src={Broucher4} alt="Travel Destination" title="Travel Destination" />
                                                    </div>
                                                </aside>
                                            </div>
                                        </Slider>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Brochure Designing" */}

                    {/* Start - Section Design "Print Media Design" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="print-principles printMediaDesignSec section_title_space sec_padding" id="print-media-design">
                            <Grid item container className="container_custom">
                                <Grid component="div" className="print-principles-inner">
                                    <Grid item xs={12} className="title_row">
                                        <aside className="">
                                            <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                                Print Media Design
                                    </Typography>
                                            <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                                We know how to utilize print media for marketing. We know the limits imposed by varying sizes of businesses, and within those limits work towards offering best solution possible. When our clients choose our <strong>print media services</strong>, they are driven by the following considerations.
                                    </Typography>
                                        </aside>
                                    </Grid>
                                    <Grid className="principles-listing" data-aos="fade-up" data-aos-duration="800">
                                        <ul>
                                            <li className="blue">
                                                <Grid className="pr-listing-inner">
                                                    <div className="img-wrap">
                                                        <img width="30" height="30" src={Informativecontent} alt="Informative Content" title="Informative Content" />
                                                    </div>
                                                </Grid>
                                                <Grid className="box-content">
                                                    <Typography variant="h4" className="head_title">
                                                        Informative Content
                                            </Typography>
                                                    <Typography className="head_paragraph">
                                                        Nothing gets you more customers than highly informative, targeted and precise content. Associating with us could prove to be the most profitable decision in this context.
                                            </Typography>
                                                </Grid>
                                            </li>
                                            <li className="red">
                                                <Grid className="pr-listing-inner">
                                                    <div className="img-wrap">
                                                        <img width="30" height="30" src={Crispprecise} alt="Crisp and Precise" title="Crisp and Precise" />
                                                    </div>
                                                </Grid>
                                                <Grid className="box-content">
                                                    <Typography variant="h4" className="head_title">
                                                        Crisp and Precise
                                            </Typography>
                                                    <Typography className="head_paragraph">
                                                        This is exactly what your prospective clients seek after. Anything trite or imprecise will shy them away from you to your competitors.
                                            </Typography>
                                                </Grid>
                                            </li>
                                            <li className="green">
                                                <Grid className="pr-listing-inner">
                                                    <div className="img-wrap">
                                                        <img width="30" height="30" src={Customerattention} alt="Customer Attention" title="Customer Attention" />
                                                    </div>
                                                </Grid>
                                                <Grid className="box-content">
                                                    <Typography variant="h4" className="head_title">
                                                        Customer Attention
                                            </Typography>
                                                    <Typography className="head_paragraph">
                                                        Nothing can grab it if not a to-the-point and witty advertisements that bang right on target, putting across to the customers what they want. We give what you want.
                                            </Typography>
                                                </Grid>
                                            </li>
                                            <li className="yellow">
                                                <Grid className="pr-listing-inner">
                                                    <div className="img-wrap">
                                                        <img width="30" height="30" src={Excitingsalescopies} alt="Exciting Sales Copies" title="Exciting Sales Copies" />
                                                    </div>
                                                </Grid>
                                                <Grid className="box-content">
                                                    <Typography variant="h4" className="head_title">
                                                        Exciting Sales Copies
                                            </Typography>
                                                    <Typography className="head_paragraph">
                                                        Being a pioneering print design company, Visions has professionals who are well-grounded in marketing terminology.
                                            </Typography>
                                                </Grid>
                                            </li>
                                            <li className="orange">
                                                <Grid className="pr-listing-inner">
                                                    <div className="img-wrap">
                                                        <img width="30" height="30" src={Costeffectiveaffordable} alt="Cost-Effective and Affordable" title="Cost-Effective and Affordable" />
                                                    </div>
                                                </Grid>
                                                <Grid className="box-content">
                                                    <Typography variant="h4" className="head_title" >
                                                        Cost-Effective and Affordable
                                            </Typography>
                                                    <Typography className="head_paragraph">
                                                        You cannot expect a better deal if you are able to land a service that satisfies your business interests without having to invest sizable amount.
                                            </Typography>
                                                </Grid>
                                            </li>
                                        </ul>
                                        <div className="print-below-listing">
                                            <img src={print_circle_top_color} alt="" />
                                            <img width="auto" height="auto" src={print_round} alt="Print Media Designing" title="Print Media Designing" />
                                            <img src={print_circle_color} alt="" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Slider className="graphicsShape1" {...settingsPrinciplesListing}>
                                            <div>
                                                <div className="box-wrapper blue">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={Informativecontent} alt="Informative Content" title="Informative Content" />
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Informative content</Typography>
                                                        <Typography className="head_paragraph">Nothing gets you more customers than highly informative, targeted and precise content. Associating with us could prove to be the most profitable decision in this context. </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box-wrapper red">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={Crispprecise} alt="Crisp and Precise" title="Crisp and Precise" />
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Crisp and precise</Typography>
                                                        <Typography className="head_paragraph">This is exactly what your prospective clients seeks after. Anything trite or imprecise will shy them away from you to your competitors.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box-wrapper green">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={Customerattention} alt="Customer Attention" title="Customer Attention" />
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Customer attention</Typography>
                                                        <Typography className="head_paragraph">Nothing can grab it if not a to-the-point and witty advertisements that bang right on target, putting across to the customers what they want. We give what you want. </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box-wrapper yellow">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={Excitingsalescopies} alt="Exciting Sales Copies" title="Exciting Sales Copies" />
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Exciting sales copies</Typography>
                                                        <Typography className="head_paragraph">Being a pioneering print design company, Visions has professionals who are well-grounded in marketing terminology. It simply means that the content we provide makes sense.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box-wrapper orange">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={Costeffectiveaffordable} alt="Cost-Effective and Affordable" title="Cost-Effective and Affordable" />
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Cost-effective and affordable</Typography>
                                                        <Typography className="head_paragraph">You cannot expect a better deal if you are able to land a service that satisfies your business interests without having to invest sizable amount. </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Print Media Design" */}

                    {/* Start - Section Design "Mobile App Design" */}
                    <Grid item xs={12}>
                        <Grid
                        component="div"
                        className="key_benfits_and_why_choose sec_padding bottomkeybenefits mobileAppSec bg-lightgrey section_title_space"
                        id="mobile-app-design"
                        >
                        <Grid item container className="container_custom boxes_listing">
                            <Grid item xs={12} className="title_row">
                            <aside>
                                <Typography
                                variant="h2"
                                className="head_title "
                                data-aos="fade-up"
                                data-aos-duration="800"
                                >
                                Mobile App Design
                                </Typography>
                                <Typography
                                className="head_paragraph "
                                data-aos="fade-up"
                                data-aos-duration="1200"
                                >
                                <strong>Visions</strong> represents understanding, delightful structure and practical arrangements, granted with top positions and a large number of five-star surveys in the application stores. We represent considerable authority in conceptualizing, forming, situating and developing mobile solutions. From our broad involvement with mobile app. improvement services with various customers over the business, we can disclose to you that finding the correct accomplice is considerably more critical than sparing a couple hundred or thousand bucks in building your item. At Visions, we have practical experience in conveying custom Mobile application development arrangements which can mechanize your business tasks and give a higher ROI to your technology capital.
                                </Typography>
                            </aside>
                            </Grid>
                            <Grid item sm={6} md={4} lg={3} className="boxes_key">
                            <div
                                className="list_key_boxes"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                            >
                                <img
                                alt="Hybrid App Development"
                                title="Hybrid App Development"
                                src={HybridAppDevelopment}
                                />
                                <Typography variant="h4" className="head_title ">
                                Hybrid App Development
                                </Typography>
                                <Typography className="head_paragraph">
                                Mixing the intensity of mobility with and cross-platform compatibility, hybrid apps. are the ideal decision for customers trying to augment their task's establishment base while limiting the general expense of their undertaking.
                                </Typography>
                            </div>
                            </Grid>
                            <Grid item sm={6} md={4} lg={3} className="boxes_key">
                            <div
                                className="list_key_boxes"
                                data-aos="fade-up"
                                data-aos-duration="1400"
                            >
                                <img
                                alt="Custom Android App Development"
                                title="Custom Android App Development"
                                src={CustomAndroidAppDevelopment}
                                />
                                <Typography variant="h4" className="head_title ">
                                Custom Android App Development
                                </Typography>
                                <Typography className="head_paragraph ">
                                As the most-used mobile operating system in the world, our programmers and designers have extensive experience producing native software for Android. Your project can run across all manner of Android devices with the help of our programmers.
                                </Typography>
                            </div>
                            </Grid>
                            <Grid item sm={6} md={4} lg={3} className="boxes_key">
                            <div
                                className="list_key_boxes"
                                data-aos="fade-up"
                                data-aos-duration="1800"
                            >
                                <img
                                alt="Native Android App Development"
                                title="Native Android App Development"
                                src={NativeAndroidAppDevelopment}
                                />
                                <Typography variant="h4" className="head_title ">
                                Native Android App Development
                                </Typography>
                                <Typography className="head_paragraph ">
                                Native applications are able to harness the full power and performance of mobile devices through specialized APIs and programming languages. Native applications are a good fit for hardware-specific applications. Developing quality Android apps is just one of the many things our team at Visions does well.
                                </Typography>
                            </div>
                            </Grid>
                            <Grid item sm={6} md={4} lg={3} className="boxes_key">
                            <div
                                className="list_key_boxes"
                                data-aos="fade-up"
                                data-aos-duration="2200"
                            >
                                <img
                                alt="Enterprise Android App Development"
                                title="Enterprise Android App Development"
                                src={EnterpriseAndroidAppDevelopment}
                                />
                                <Typography variant="h4" className="head_title ">
                                Enterprise Android App Development
                                </Typography>
                                <Typography className="head_paragraph ">
                                The focal point of Enterprise application advancement ought to be on the elements generally essential to the customer; for example, ease of use, visual plan highlights, programming, security, and quality confirmation. At Visions, these components remain our essential worry all through the whole procedure.
                                </Typography>
                            </div>
                            </Grid>
                        </Grid>
                        <Grid className="container_custom">
                            <SliderServiceCatPagePortfolio3 data={mobileAppSlider} />
                        </Grid>
                        <Grid item xs={12} className="btn-wrap" data-aos="fade-up" data-aos-duration="1000">
                            <Link to="/hire-developers/hire-android-app-developers" className="dark-btn-effect all-category-mobile" title="Hire Android App Developers">
                            Hire Android App Developers
                            </Link>
                        </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Mobile App Design" */}

                    {/* Start - NumberCount Import From Common Components */}
                    <Suspense fallback={<div></div>}>
                    <NumberCount />
                    </Suspense>
                    {/* End - NumberCount Import From Common Components */}
                    
                </Grid>
            </div>
        );
    }
}

export default UiUxDesign;
