import React, { Component, Suspense, lazy } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

// Get Files for Slider Start
import Slider from "../slider";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
// Get Files for Slider End

// Get Images Start
import UI_UX_ICON from "../assets/images/HomePage/UI_UX.svg";
import WEBSITE_ICON from "../assets/images/HomePage/web_development.svg";
import MOBILE_ICON from "../assets/images/HomePage/application_development.svg";
import SEO_ICON from "../assets/images/HomePage/digital_marketing.svg";
import ecommerce_icon from "../assets/images/HomePage/ecommerce_development.svg";
import network_icon from "../assets/images/HomePage/Network_Admin_Support.svg";
import Dylan_Marriott from "../assets/images/HomePage/Dylan-Marriott.jpg";
import Narelle_Bettini from "../assets/images/HomePage/Narelle_Bettini.jpg";
import Don_Cherry from "../assets/images/HomePage/Don_Cherry.png";
import tony_knutsen from "../assets/images/HomePage/tony_knutsen.jpg";
import Douglas_McKalip from "../assets/images/HomePage/Douglas_McKalip.jpg";
import Spencer_Sharpe from "../assets/images/HomePage/Spencer_Sharpe.jpg";
import Ante_Kordic from "../assets/images/HomePage/Ante_Kordic.jpg";
import Carl_Dennie from "../assets/images/HomePage/Carl-Dennie.jpg";
import node_js from "../assets/images/HomePage/Node-js-logo.svg";
import node_js_white from "../assets/images/HomePage/Node.js-logo-white.svg";
import mean_logo from "../assets/images/HomePage/mean-logo.svg";
import mean_white_logo from "../assets/images/HomePage/mean-white-logo.svg";
import mern_logo from "../assets/images/HomePage/mern-logo.svg";
import mern_white_logo from "../assets/images/HomePage/mern-white-logo.svg";
import apple_logo from "../assets/images/HomePage/apple_logo.svg";
import apple_logo_white from "../assets/images/HomePage/apple_logo-white.svg";
import android_logo from "../assets/images/HomePage/android-logo.svg";
import android_logo_white from "../assets/images/HomePage/android-logo-white.svg";
import python_logo from "../assets/images/HomePage/python-logo.svg";
import python_logo_white from "../assets/images/HomePage/python-logo-white.svg";
import php_logo from "../assets/images/HomePage/php-logo.svg";
import php_white_logo from "../assets/images/HomePage/php-white-logo.svg";
import Reactjs_logo from "../assets/images/HomePage/React.js-logo.svg";
import Reactjs_logo_white from "../assets/images/HomePage/React.js-logo-white.svg";
import angular_logo from "../assets/images/HomePage/angular-logo.svg";
import angular_logo_white from "../assets/images/HomePage/angular-logo-white.svg";
import microsoft_logo from "../assets/images/HomePage/microsoft-logo.svg";
import microsoft_logo_white from "../assets/images/HomePage/microsoft-logo-white.svg";
import Wordpress_Blue_logo from "../assets/images/HomePage/Wordpress_Blue_logo.svg";
import Wordpress_Blue_logo_white from "../assets/images/HomePage/Wordpress_Blue_logo-white.png";
import magento_logo from "../assets/images/HomePage/magento-logo.svg";
import magento_logo_white from "../assets/images/HomePage/magento-logo-white.svg";
import drupal_logo from "../assets/images/HomePage/drupal-logo.svg";
import drupal_logo_white from "../assets/images/HomePage/drupal-logo-white.svg";
import mevn_logo from "../assets/images/HomePage/mevn-logo.svg";
import mevn_white_logo from "../assets/images/HomePage/mevn-white-logo.svg";
import dontNetIcon from "../assets/images/HomePage/dontNetIcon.svg";
import seo_colored from "../assets/images/colored/seo.svg";
import seo2_colored from "../assets/images/colored/seo2.svg";
import socialMediaSeo_colored from "../assets/images/colored/social-media-seo.svg";
import online_reputation_icon_colored from "../assets/images/colored/online_reputation_icon.svg";
import landingpage_colored from "../assets/images/colored/wesite-design.svg";
import responsive_colored from "../assets/images/colored/responsive.svg";
import logoDesign_colored from "../assets/images/colored/logo_design.svg";
import bannerDesign_colored from "../assets/images/colored/banner_design.svg";
import brochureDesign_colored from "../assets/images/colored/brochure_design.svg";
// import printmedia_colored from "../assets/images/colored/printmedia.svg";
import MobileAppIcon_colored from "../assets/images/colored/MobileAppIcon.svg";
// import magento_colored from "../assets/images/colored/magento.svg";
import woocommerce_colored from "../assets/images/colored/woo.svg";
import shopify_colored from "../assets/images/colored/shopify.svg";
import opencart_colored from "../assets/images/colored/opencart.svg";
import headerbigcommerceiconcolored from "../assets/images/colored/header-bigcommerce-icon.svg";
import header_awsCloud_icon_colored from "../assets/images/colored/header-awsCloud-icon.svg";
import server_admin_support_colored from "../assets/images/colored/server_admin_support.svg";
import header_linux_icon_colored from "../assets/images/colored/header-linux-icon.svg";
import header_emailmigration_icon_colored from "../assets/images/colored/header_emailmigration_icon.svg";
import homeBanner1 from "../assets/images/HomePage/homeBanner1.jpg";
import homeBanner2 from "../assets/images/HomePage/homeBanner2.jpg";
import homeBanner3 from "../assets/images/HomePage/homeBanner3.jpg";
// Get Images End

// Custom Stylesheets Start
import "../assets/css/HomepageStyle.css";
// Custom Stylesheets End

// Get Common Components Start
//import NumberCount from "./common/NumberCount";
import HomeBannerSlider from "./common/HomeBannerSlider";
//import OurClients from "./common/OurClients";
//import DiscussYourProject from "./common/DiscussYourProject";
//import HomePortfolioSlider from "./common/HomePortfolioSlider";
// Get Common Components End

import LazyLoad from "react-lazyload";
const HomePortfolioSlider = lazy(() => import("./common/HomePortfolioSlider"));
const NumberCount = lazy(() => import("./common/NumberCount"));
const OurClients = lazy(() => import("./common/OurClients"));
const DiscussYourProject = lazy(() => import("./common/DiscussYourProject"));

class HomePage extends Component {
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }

    const homeBannerSlider1Data = [
      {
        title: 'Web Development Company',
        titleWhite: '',
        subTitle: 'We are a',
        description: 'All applications are tailored-made with exquisitely robust user experiences to meet your specific industry needs. Our dedicated web developers have successfully completed 1000+ web projects.',
        img: homeBanner1,
      },
      {
        title: 'Mobile App Development',
        titleWhite: '',
        subTitle: 'Our End-to-End',
        description: 'Being a leading mobile app development company, Visions Software has extensive experience in creating enterprise-grade, native & hybrid mobile applications.',
        img: homeBanner2,
      },
      {
        title: 'Search Engine Optimization',
        titleWhite: '',
        subTitle: 'Taking your business to next level',
        description: 'Visions as a top - rated Search Engine Optimization Agency in India feel proud to deliver successful SEO services in the most competitive sectors in different countries of the world.Let us help you to bring your business on the top search results in search engine result pages.',
        img: homeBanner3,
      }
    ];
    this.setState({ homeBannerSlider1: homeBannerSlider1Data });
  }

  moveToSpecifLocation = () => {
    window.scrollTo({
      top: 2960,
      behavior: "smooth"
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      dSolution: {
        middleimage: "UI_UX_ICON",
        addclassmiddle: "active",
        addclassmiddle1: "",
        addclassmiddle2: "",
        addclassmiddle3: "",
        addclassmiddle4: "",
        addclassmiddle5: "",
        homeBannerSlider1: []
      }
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  handleOnHoverIcons(icon) {
    switch (icon) {
      case "UI_UX_ICON":
        this.setState(prevState => ({
          dSolution: {
            ...prevState.dSolution,
            middleimage: 'UI_UX_ICON',
            addclassmiddle1: '',
            addclassmiddle2: '',
            addclassmiddle3: '',
            addclassmiddle4: '',
            addclassmiddle5: '',
            addclassmiddle: 'active'
          }
        }));
        break;
      case "WEBSITE_ICON":
        this.setState(prevState => ({
          dSolution: {
            ...prevState.dSolution,
            middleimage: 'WEBSITE_ICON',
            addclassmiddle: '',
            addclassmiddle2: '',
            addclassmiddle3: '',
            addclassmiddle4: '',
            addclassmiddle5: '',
            addclassmiddle1: 'active'
          }
        }));
        break;
      case "FRONTEND_ICON":
        this.setState(prevState => ({
          dSolution: {
            ...prevState.dSolution,
            middleimage: 'FRONTEND_ICON',
            addclassmiddle: '',
            addclassmiddle1: '',
            addclassmiddle3: '',
            addclassmiddle4: '',
            addclassmiddle5: '',
            addclassmiddle2: 'active'
          }
        }));
        break;
      case "APPDEV_ICON":
        this.setState(prevState => ({
          dSolution: {
            ...prevState.dSolution,
            middleimage: 'APPDEV_ICON',
            addclassmiddle: '',
            addclassmiddle1: '',
            addclassmiddle2: '',
            addclassmiddle4: '',
            addclassmiddle5: '',
            addclassmiddle3: 'active'
          }
        }));
        break;
      case "MOBILE_ICON":
        this.setState(prevState => ({
          dSolution: {
            ...prevState.dSolution,
            middleimage: 'MOBILE_ICON',
            addclassmiddle: '',
            addclassmiddle1: '',
            addclassmiddle2: '',
            addclassmiddle3: '',
            addclassmiddle5: '',
            addclassmiddle4: 'active'
          }
        }));
        break;
      case "SEO_ICON":
        this.setState(prevState => ({
          dSolution: {
            ...prevState.dSolution,
            middleimage: 'SEO_ICON',
            addclassmiddle: '',
            addclassmiddle1: '',
            addclassmiddle2: '',
            addclassmiddle3: '',
            addclassmiddle4: '',
            addclassmiddle5: 'active'
          }
        }));
        break;
      default:
        this.setState(prevState => ({
          dSolution: {
            ...prevState.dSolution,
            middleimage: 'UI_UX_ICON',
            addclassmiddle1: '',
            addclassmiddle2: '',
            addclassmiddle3: '',
            addclassmiddle4: '',
            addclassmiddle5: '',
            addclassmiddle: 'active'
          }
        }));
    }
  }

  render() {
    document.title = "Web Design & App Development Company | Visions Software";
    document.description = "Visions has a creative web design, mobile app development, and software development team in India proudly servicing clients worldwide. Hire us today!";
    const { homeBannerSlider1 } = this.state;

    const settingstestimonialslider = {
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      adaptiveHeight: false,
      autoplay: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true
          }
        },
      ]
    };

    const newbuildmobile = {
      infinite: true,
      dots: false,
      arrows: true,
      accessibility: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      speed: 500,
      autoplay: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    const webiconsmobile = {
      infinite: true,
      dots: false,
      arrows: true,
      accessibility: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      speed: 500,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 5,
            centerMode: true,
            centerPadding: '0',
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            centerPadding: '0',
          }
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '80px',
          }
        }
      ]
    };

    return (
      <main>
        <Grid container>

          {/* Start - HomeBannerSlider Import From Common Components */}
            <HomeBannerSlider slides={homeBannerSlider1} />
          {/* End - HomeBannerSlider Import From Common Components */}

          {/* Start - Section Design "What We Offer To Our Clients" */}
          <Grid component="div" className="new-build-wrapper sec_padding" data-aos="fade-up" data-aos-duration="1500">
            <Grid item container className="container_custom">
              <Typography variant="h2" className="head_title">
              What We Offer To Our Clients !!!
              </Typography>
              <Typography className="head_paragraph">
              <strong>Visions</strong> has a creative web design and <strong>software development</strong> team in India proudly servicing clients world wide. We specialise in <strong>web design & development</strong>, customised stand alone software development as well as <strong>Mobile Applications</strong>. Our expertise includes <strong>Magento, MEAN Stack, MERN Stack, React Native</strong> development, <strong>Python, UI / UX</strong> Design, <strong>PHP</strong>, Hybrid Mobile Apps across <strong>Android & iPhone</strong> platforms.
              </Typography>
              <Grid item xs={12} component="div" className="new-build-three-flex-wrapper">
                <Grid component="div" className="new-build-first-wrapper left">
                  <ul>
                    <li
                      className={`${this.state.dSolution.addclassmiddle}`}
                      onMouseEnter={() => this.handleOnHoverIcons("UI_UX_ICON")}
                    >
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/web-development-services"
                            title="Web Development"
                          >
                            Web development
                          </Link>
                        </Typography>
                        <Typography>
                          Engage users with world class websites and web applications.
                        </Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/web-development-services/php-development">
                            <img width="26" height="26"  src={php_logo} alt="PHP Development" title="PHP Development" />
                          </Link>
                          <Link to="/services/web-development-services/mern-stack-development">
                            <img width="26" height="26"  src={mern_logo} alt="Mern Development" title="Mern Development" />
                          </Link>
                          <Link to="/services/web-development-services/mean-stack-development">
                            <img width="26" height="26"  src={mean_logo} alt="Mean Development" title="Mean Development" />
                          </Link>
                          <Link to="/services/web-development-services/mevn-stack-development">
                            <img width="26" height="26"  src={mevn_logo} alt="Mevn Development" title="Mevn Development" />
                          </Link>
                          {/* <Link to="/services/web-development-services/asp-net-development">
                            <img width="26" height="26"  src={dontNetIcon} alt="ASP.Net Development" title="ASP.Net Development" />
                          </Link> */}
                          <Link to="/services/web-development-services/python-development">
                            <img width="26" height="26"  src={python_logo} alt="Python Development" title="Python Development" />
                          </Link>
                        </div>
                      </div>
                      <Link className="left" to="/services/web-development-services">
                      <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={WEBSITE_ICON}
                          alt="Web Development"
                          title="Web Development"
                        />
                      </LazyLoad>
                      </Link>
                    </li>
                    <li
                      className={`${this.state.dSolution.addclassmiddle1}`}
                      onMouseEnter={() =>
                        this.handleOnHoverIcons("WEBSITE_ICON")
                      }
                    >
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/app-development-services"
                            title="Mobile App Development"
                          >
                            Mobile App development
                          </Link>
                        </Typography>
                        <Typography>
                          Our highly skilled developers builds easy to use, interactive, scalable and extensible applications that build businesses.
                        </Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/app-development-services/android-app-development"><img width="26" height="26"  src={android_logo} alt="Android Development" title="Android Development" /></Link>
                          <Link to="/services/app-development-services/ios-app-development"><img width="26" height="26"  src={apple_logo} alt="iOS Development" title="iOS Development" /></Link>
                          <Link to="/services/app-development-services/react-native-development"><img width="26" height="26"  src={Reactjs_logo} alt="React Native Development" title="React Native Development" /></Link>
                        </div>
                      </div>
                      <Link className="left" to="/services/app-development-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={MOBILE_ICON}
                          alt="Mobile App Development"
                          title="Mobile App Development"
                        />
                        </LazyLoad>
                      </Link>
                    </li>
                    <li
                      className={`${this.state.dSolution.addclassmiddle2}`}
                      onMouseEnter={() =>
                        this.handleOnHoverIcons("FRONTEND_ICON")
                      }
                    >
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/digital-marketing-services"
                            title="Digital marketing"
                          >
                            Digital marketing
                          </Link>
                        </Typography>
                        <Typography>
                          We use of numerous digital tactics and channels to connect with customers where they spend much of their time online.
                        </Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/digital-marketing-services/seo-services-india"><img width="26" height="26"  src={seo_colored} alt="Search Engine Optimization" title="Search Engine Optimization" /></Link>
                          <Link to="/services/digital-marketing-services/local-seo"><img width="26" height="26"  src={seo2_colored} alt="Local SEO" title="Local SEO" /></Link>
                          <Link to="/services/digital-marketing-services/social-media-optimization"><img width="26" height="26"  src={socialMediaSeo_colored} alt="Social Media Optimization" title="Social Media Optimization" /></Link>
                          <Link to="/services/digital-marketing-services/online-reputation-management"><img width="26" height="26"  src={online_reputation_icon_colored} alt="Online Reputation Management" title="Online Reputation Management" /></Link>
                        </div>
                      </div>
                      <Link className="left" to="/services/digital-marketing-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={SEO_ICON}
                          alt="Digital marketingn"
                          title="Digital marketing"
                        />
                        </LazyLoad>
                      </Link>
                    </li>
                  </ul>
                </Grid>
                <Grid component="div" className="new-build-first-wrapper middle">
                  <div className={`sp-image left ${this.state.dSolution.addclassmiddle}`}>
                    {`${this.state.dSolution.middleimage}` === "UI_UX_ICON" && (
                      <LazyLoad once offset={300}><img
                        src={`${WEBSITE_ICON}`}
                        title="Web Development"
                        alt="Web Development"
                      /></LazyLoad>
                    )}
                  </div>
                  <div className={`sp-image left ${this.state.dSolution.addclassmiddle1}`}>
                    {`${this.state.dSolution.middleimage}` === "WEBSITE_ICON" && (
                      <LazyLoad once offset={300}><img
                        src={`${MOBILE_ICON}`}
                        title="Mobile App Development"
                        alt="Mobile App Development"
                      /></LazyLoad>
                    )}
                  </div>
                  <div className={`sp-image left ${this.state.dSolution.addclassmiddle2}`}>
                    {`${this.state.dSolution.middleimage}` === "FRONTEND_ICON" && (
                      <LazyLoad once offset={300}><img
                        src={`${SEO_ICON}`}
                        title="Digital Marketing"
                        alt="Digital Marketing"
                      /></LazyLoad>
                    )}
                  </div>
                  <div className={`sp-image right ${this.state.dSolution.addclassmiddle3}`}>
                    {`${this.state.dSolution.middleimage}` === "APPDEV_ICON" && (
                      <LazyLoad once offset={300}><img
                        src={`${UI_UX_ICON}`}
                        title="UI/UX"
                        alt="UI/UX"
                      /></LazyLoad>
                    )}
                  </div>
                  <div className={`sp-image right ${this.state.dSolution.addclassmiddle4}`}>
                    {`${this.state.dSolution.middleimage}` === "MOBILE_ICON" && (
                      <LazyLoad once offset={300}><img
                        src={`${ecommerce_icon}`}
                        title="Ecommerce Solutions"
                        alt="Ecommerce Solutions"
                      /></LazyLoad>
                    )}
                  </div>
                  <div className={`sp-image right ${this.state.dSolution.addclassmiddle5}`}>
                    {`${this.state.dSolution.middleimage}` === "SEO_ICON" && (
                      <LazyLoad once offset={300}><img
                        src={`${network_icon}`}
                        title="Server/Network Admin Support"
                        alt="Server/Network Admin Support"
                      /></LazyLoad>
                    )}
                  </div>
                </Grid>
                <Grid component="div" className="new-build-first-wrapper right">
                  <ul>
                    <li
                      className={`${this.state.dSolution.addclassmiddle3}`}
                      onMouseEnter={() =>
                        this.handleOnHoverIcons("APPDEV_ICON")
                      }
                    >
                      <Link className="left" to="/services/uiux-design-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={UI_UX_ICON}
                          alt="UI/UX"
                          title="UI/UX"
                        />
                        </LazyLoad>
                      </Link>
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/uiux-design-services"
                            title="UI/UX"
                          >
                            UI/UX
                          </Link>
                        </Typography>
                        <Typography>
                          Superlative user interface design (UI) and user experience design (UX) services.
                        </Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/uiux-design-services/website-redesign"><img width="26" height="26" src={landingpage_colored} alt="Website Redesign" title="Website Redesign" /></Link>
                          <Link to="/services/uiux-design-services/responsive-web-design"><img width="26" height="26"  src={responsive_colored} alt="Responsive Web Design" title="Responsive Web Design" /></Link>
                          <Link to="/services/uiux-design-services/logo-designing"><img width="26" height="26"  src={logoDesign_colored} alt="Logo Designing" title="Logo Designing" /></Link>
                          <Link to="/services/uiux-design-services/banner-designing"><img width="26" height="26"  src={bannerDesign_colored} alt="Banner Designing" title="Banner Designing" /></Link>
                          <Link to="/services/uiux-design-services/brochure-designing"><img width="26" height="26"  src={brochureDesign_colored} alt="Broucher Designing" title="Broucher Designing" /></Link>
                          {/* <Link to="/services/uiux-design-services/print-media-design"><img width="26" height="26"  src={printmedia_colored} alt="Print Media Design" title="Print Media Design" /></Link> */}
                          <Link to="/services/uiux-design-services/mobile-app-design"><img width="26" height="26"  src={MobileAppIcon_colored} alt="Mobile App Development" title="Mobile App Development" /></Link>                        
                        </div>
                      </div>
                    </li>
                    <li
                      className={`${this.state.dSolution.addclassmiddle4}`}
                      onMouseEnter={() =>
                        this.handleOnHoverIcons("MOBILE_ICON")
                      }
                    >
                      <Link className="left" to="/services/ecommerce-development-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={ecommerce_icon}
                          alt="Ecommerce Solutions"
                          title="Ecommerce Solutions"
                        />
                        </LazyLoad>
                      </Link>
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/ecommerce-development-services"
                            title="Ecommerce Solutions"
                          >
                            Ecommerce Solutions
                          </Link>
                        </Typography>
                        <Typography>
                          Our team supports and delivers you the astoundingly better result for your business.
                        </Typography>
                        <div className="tech_icons_wrapper">
                          {/* <Link to="/services/ecommerce-development-services/magento-development"><img width="26" height="26"  src={magento_logo} alt="Magento Development" title="Magento Development" /></Link> */}
                          <Link to="/services/ecommerce-development-services/woocommerce-development"><img width="26" height="26"  src={woocommerce_colored} alt="Woocommerce Development" title="Woocommerce Development" /></Link>
                          <Link to="/services/ecommerce-development-services/shopify-development"><img width="26" height="26"  src={shopify_colored} alt="Shopify Development" title="Shopify Development" /></Link>
                          {/* <Link to="/services/ecommerce-development-services/openCart-development"><img width="26" height="26"  src={opencart_colored} alt="OpenCart Development" title="OpenCart Development" /></Link> */}
                          <Link to="/services/ecommerce-development-services/bigcommerce-development"><img width="26" height="26"  src={headerbigcommerceiconcolored} alt="BigCommerce Development" title="BigCommerce Development" /></Link>
                        </div>
                      </div>
                    </li>
                    <li
                      className={`${this.state.dSolution.addclassmiddle5}`}
                      onMouseEnter={() => this.handleOnHoverIcons("SEO_ICON")}
                    >
                      <Link className="left" to="/services/server-network-admin-support-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={network_icon}
                          alt="Server/Network Admin support"
                          title="Server/Network Admin support"
                        />
                        </LazyLoad>
                      </Link>
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/server-network-admin-support-services"
                            title="Server/Network Admin support"
                          >
                            Server/Network Admin support
                          </Link>
                        </Typography>
                        <Typography>We provide technical support, server administration, maintenance and Linux &  Windows Servers Management.</Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/server-network-admin-support-services/server-network-administration"><img width="26" height="26"  src={server_admin_support_colored} alt="Server Network Administration" title="Server Network Administration" /></Link>
                          <Link to="/services/server-network-admin-support-services/aws-cloud-hosting"><img width="26" height="26"  src={header_awsCloud_icon_colored} alt="AWS Cloud Hosting" title="AWS Cloud Hosting" /></Link>                          
                          <Link to="/services/server-network-admin-support-services/linux"><img width="26" height="26"  src={header_linux_icon_colored} alt="Linux" title="Linux" /></Link>
                          <Link to="/services/server-network-admin-support-services/email-migration"><img width="26" height="26"  src={header_emailmigration_icon_colored} alt="Email Migration" title="Email Migration" /></Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Grid>
              </Grid>

              {/* Start - Section Design For Mobile/Tab "What We Offer To Our Clients" */}
              <Grid item xs={12} className="new-build-mobile-section">
                <Slider {...newbuildmobile}>
                  <div>
                    <li data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500"
                      className="aos-init aos-animate">
                      <Link className="left" to="/services/web-development-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={WEBSITE_ICON}
                          alt="Web Development"
                          title="Web Development"
                        />
                        </LazyLoad>
                      </Link>
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/web-development-services"
                            title="Web Development"
                          >
                            Web development
                          </Link>
                        </Typography>
                        <Typography>
                          Engage users with world class websites and web applications.
                        </Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/web-development-services/php-development"><img width="26" height="26"  src={php_logo} alt="PHP Development" title="PHP Development" /></Link>
                          <Link to="/services/web-development-services/mern-stack-development"><img width="26" height="26"  src={mern_logo} alt="Mern Development" title="Mern Development" /></Link>
                          <Link to="/services/web-development-services/mean-stack-development"><img width="26" height="26"  src={mean_logo} alt="Mean Development" title="Mean Development" /></Link>
                          <Link to="/services/web-development-services/mevn-stack-development"><img width="26" height="26"  src={mevn_logo} alt="Mevn Development" title="Mevn Development" /></Link>
                          {/* <Link to="/services/web-development-services/asp-net-development"><img width="26" height="26"  src={dontNetIcon} alt="ASP.Net Development" title="ASP.Net Development" /></Link> */}
                          <Link to="/services/web-development-services/python-development"><img width="26" height="26"  src={python_logo} alt="Python Development" title="Python Development" /></Link>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div>
                    <li data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500"
                      className="aos-init aos-animate">
                      <Link className="left" to="/services/app-development-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={MOBILE_ICON}
                          alt="Mobile App Development"
                          title="Mobile App Development"
                        />
                        </LazyLoad>
                      </Link>
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/app-development-services"
                            title="Mobile App development"
                          >
                            Mobile App development
                          </Link>
                        </Typography>
                        <Typography>
                          Our highly skilled developers builds easy to use, interactive, scalable and extensible applications that build businesses.
                        </Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/app-development-services/android-app-development"><img width="26" height="26"  src={android_logo} alt="Android Development" title="Android Development" /></Link>
                          <Link to="/services/app-development-services/ios-app-development"><img width="26" height="26"  src={apple_logo} alt="iOS Development" title="iOS Development" /></Link>
                          <Link to="/services/app-development-services/react-native-development"><img width="26" height="26"  src={Reactjs_logo} alt="React Native Development" title="React Native Development" /></Link>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div>
                    <li data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500"
                      className="aos-init aos-animate">
                      <Link className="left" to="/services/digital-marketing-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={SEO_ICON}
                          alt="Digital Marketing"
                          title="Digital Marketing"
                        />
                        </LazyLoad>
                      </Link>
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/digital-marketing-services"
                            title="Digital marketing"
                          >
                            Digital marketing
                          </Link>
                        </Typography>
                        <Typography>
                          We use of numerous digital tactics and channels to connect with customers where they spend much of their time online.
                        </Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/digital-marketing-services/seo-services-india"><img width="26" height="26"  src={seo_colored} alt="Search Engine Optimization" title="Search Engine Optimization" /></Link>
                          <Link to="/services/digital-marketing-services/local-seo"><img width="26" height="26"  src={seo2_colored} alt="Local SEO" title="Local SEO" /></Link>
                          <Link to="/services/digital-marketing-services/social-media-optimization"><img width="26" height="26"  src={socialMediaSeo_colored} alt="Social Media Optimization" title="Social Media Optimization" /></Link>
                          <Link to="/services/digital-marketing-services/online-reputation-management"><img width="26" height="26"  src={online_reputation_icon_colored} alt="Online Reputation Management" title="Online Reputation Management" /></Link>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div>
                    <li data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500"
                      className="aos-init aos-animate">
                      <Link className="left" to="/services/uiux-design-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={UI_UX_ICON}
                          alt="UI/UX"
                          title="UI/UX"
                        />
                        </LazyLoad>
                      </Link>
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/uiux-design-services"
                            title="UI/UX"
                          >
                            UI/UX
                        </Link>
                        </Typography>
                        <Typography>
                          Superlative user interface design (UI) and user experience design (UX) services.
                      </Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/uiux-design-services/website-redesign"><img width="26" height="26"  src={landingpage_colored} alt="Website Redesign" title="Website Redesign" /></Link>
                          <Link to="/services/uiux-design-services/responsive-web-design"><img width="26" height="26"  src={responsive_colored} alt="Responsive Web Design" title="Responsive Web Design" /></Link>
                          <Link to="/services/uiux-design-services/logo-designing"><img width="26" height="26"  src={logoDesign_colored} alt="Logo Designing" title="Logo Designing" /></Link>
                          <Link to="/services/uiux-design-services/banner-designing"><img width="26" height="26"  src={bannerDesign_colored} alt="Banner Designing" title="Banner Designing" /></Link>
                          <Link to="/services/uiux-design-services/brochure-designing"><img width="26" height="26"  src={brochureDesign_colored} alt="Broucher Designing" title="Broucher Designing" /></Link>
                          {/* <Link to="/services/uiux-design-services/print-media-design"><img width="26" height="26"  src={printmedia_colored} alt="Print Media Design" title="Print Media Design" /></Link> */}
                          <Link to="/services/uiux-design-services/mobile-app-design"><img width="26" height="26"  src={MobileAppIcon_colored} alt="Mobile App Development" title="Mobile App Development" /></Link>  
                        </div>
                      </div>
                    </li>
                  </div>
                  <div>
                    <li data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500"
                      className="aos-init aos-animate">
                      <Link className="left" to="/services/ecommerce-development-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={ecommerce_icon}
                          alt="Ecommerce Solutions"
                          title="Ecommerce Solutions"
                        />
                        </LazyLoad>
                      </Link>
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/ecommerce-development-services"
                            title="Ecommerce Solutions"
                          >
                            Ecommerce Solutions
                          </Link>
                        </Typography>
                        <Typography>
                          Our team supports and delivers you the astoundingly better result for your business.
                        </Typography>
                        <div className="tech_icons_wrapper">
                          {/* <Link to="/services/ecommerce-development-services/magento-development"><img width="26" height="26"  src={magento_logo} alt="Magento Development" title="Magento Development" /></Link> */}
                          <Link to="/services/ecommerce-development-services/woocommerce-development"><img width="26" height="26"  src={woocommerce_colored} alt="Woocommerce Development" title="Woocommerce Development" /></Link>
                          <Link to="/services/ecommerce-development-services/shopify-development"><img width="26" height="26"  src={shopify_colored} alt="Shopify Development" title="Shopify Development" /></Link>
                          {/* <Link to="/services/ecommerce-development-services/openCart-development"><img width="26" height="26"  src={opencart_colored} alt="OpenCart Development" title="OpenCart Development" /></Link> */}
                          <Link to="/services/ecommerce-development-services/bigcommerce-development"><img width="26" height="26"  src={headerbigcommerceiconcolored} alt="BigCommerce Development" title="BigCommerce Development" /></Link>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div>
                    <li data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500"
                      className="aos-init aos-animate">
                      <Link className="left" to="/services/server-network-admin-support-services">
                        <LazyLoad once offset={300}>
                        <img
                          width="100"
                          height="auto"
                          src={network_icon}
                          alt="Server/Network Admin support"
                          title="Server/Network Admin support"
                        />
                        </LazyLoad>
                      </Link>
                      <div className="right">
                        <Typography variant="h4">
                          <Link
                            to="/services/server-network-admin-support-services"
                            title="Server/Network Admin support"
                          >
                            Server/Network Admin support
                          </Link>
                        </Typography>
                        <Typography>We provide technical support, server administration, maintenance and Linux &  Windows Servers Management.</Typography>
                        <div className="tech_icons_wrapper">
                          <Link to="/services/server-network-admin-support-services/server-network-administration"><img width="26" height="26"  src={server_admin_support_colored} alt="Server Network Administration" title="Server Network Administration" /></Link>
                          <Link to="/services/server-network-admin-support-services/aws-cloud-hosting"><img width="26" height="26"  src={header_awsCloud_icon_colored} alt="AWS Cloud Hosting" title="AWS Cloud Hosting" /></Link> 
                          <Link to="/services/server-network-admin-support-services/linux"><img width="26" height="26"  src={header_linux_icon_colored} alt="Linux" title="Linux" /></Link>
                          <Link to="/services/server-network-admin-support-services/email-migration"><img width="26" height="26"  src={header_emailmigration_icon_colored} alt="Email Migration" title="Email Migration" /></Link>
                        </div>
                      </div>
                    </li>
                  </div>
                </Slider>
              </Grid>
              {/* End - Section Design For Mobile/Tab "What We Offer To Our Clients" */}

            </Grid>
          </Grid>
          {/* End - Section Design "What We Offer To Our Clients" */}
          
          {/* Start - Section Design "Web Development Is Our Passion" */}
          <Grid item xs={12} className="maingray development_our_passion">
            <Grid item className="container_custom">
              <Grid item container className="web-dev-passion-main">
                <div className="web-dev-passion-left" data-aos="fade-right">
                  <h2 className="head_title">Web Development is Our Passion</h2>
                  <Typography className="head_paragraph text-left">
                    We take great pride in producing exceptional websites that effectively combine technology and design. Most of the websites that we build are created with a modern, professional style in mind. Every site is built from the ground up (we do not use templates), making each project unique, allowing you the opportunity to attract the attention it deserves.
                    </Typography>
                  <Typography className="head_paragraph text-left">
                    We are experts in <strong>web designing and development</strong> with focus on quality of work that we do! We always aim to deliver every project with higher quality standards.
                    </Typography>
                  <Typography className="head_paragraph text-left">
                    <strong><em>Best resources are couple of steps away from you.</em></strong>
                  </Typography>
                  <div component="div" className="btnWrap">
                    <Link
                      className="hover-btn-animation-in"
                      to="/portfolios"
                      title="View Our Portfolio"
                    >
                      View Our Portfolio
                      </Link>
                    <Link
                      className="hover-btn-animation-out hire_us_btn"
                      to="/hire-developers/hire-android-app-developers"
                      title="Hire Us"
                    >
                      Hire Us
                      </Link>
                  </div>
                </div>
                <div className="web-dev-passion-right">
                  <ul>
                    <li>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="50">
                        <Link to="/services/web-development-services/mevn-stack-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={node_js} alt="NodeJs Development" title="NodeJs Development" />
                            <img width="60" height="60" className="withHoverImg" src={node_js_white} alt="NodeJs Development" title="NodeJs Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                      <aside>
                        <div className="hexagonal_shape blank">
                        </div>
                      </aside>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="50">
                        <Link to="/services/web-development-services/mean-stack-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={mean_logo} alt="Mean Development" title="Mean Development" />
                            <img width="60" height="60" className="withHoverImg" src={mean_white_logo} alt="Mean Development" title="Mean Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                    </li>
                    <li>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="100">
                        <Link to="/services/app-development-services/ios-app-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={apple_logo} alt="iOS Development" title="iOS Development" />
                            <img width="60" height="60" className="withHoverImg" src={apple_logo_white} alt="iOS Development" title="iOS Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="150">
                        <Link to="/services/app-development-services/android-app-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={android_logo} alt="Android Development" title="Android Development" />
                            <img width="60" height="60" className="withHoverImg" src={android_logo_white} alt="Android Development" title="Android Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="200">
                        <Link to="/services/web-development-services/python-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={python_logo} alt="Python Development" title="Python Development" />
                            <img width="60" height="60" className="withHoverImg" src={python_logo_white} alt="Python Development" title="Python Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="250">
                        <Link to="/services/web-development-services/php-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={php_logo} alt="PHP Development" title="PHP Development" />
                            <img width="60" height="60" className="withHoverImg" src={php_white_logo} alt="PHP Development" title="PHP Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                    </li>
                    <li>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="300">
                        <Link to="/services/app-development-services/react-native-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={Reactjs_logo} alt="React Native Development" title="React Native Development" />
                            <img width="60" height="60" className="withHoverImg" src={Reactjs_logo_white} alt="React Native Development" title="React Native Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="50">
                        <Link to="/services/web-development-services/mern-stack-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={mern_logo} alt="Mern Development" title="Mern Development" />
                            <img width="60" height="60" className="withHoverImg" src={mern_white_logo} alt="Mern Development" title="Mern Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="400">
                        <Link to="/services/web-development-services/mean-stack-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={angular_logo} alt="Angular Development" title="Angular Development" />
                            <img width="60" height="60" className="withHoverImg" src={angular_logo_white} alt="Angular Development" title="Angular Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                    </li>
                    <li>
                      <aside>
                        <div className="hexagonal_shape blank">
                        </div>
                      </aside>
                      {/* <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="450">
                        <Link to="/services/web-development-services/asp-net-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={microsoft_logo} alt="ASP.NET Development" title="ASP.NET Development" />
                            <img width="60" height="60" className="withHoverImg" src={microsoft_logo_white} alt="ASP.NET Development" title="ASP.NET Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside> */}
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="500">
                        <Link to="/services/web-development-services/php-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={Wordpress_Blue_logo} alt="Wordpress Development" title="Wordpress Development" />
                            <img width="60" height="60" className="withHoverImg" src={Wordpress_Blue_logo_white} alt="Wordpress Development" title="Wordpress Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                    </li>
                    <li>
                      {/* <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="550">
                        <Link to="/services/ecommerce-development-services/magento-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={magento_logo} alt="Magento Development" title="Magento Development" />
                            <img width="60" height="60" className="withHoverImg" src={magento_logo_white} alt="Magento Development" title="Magento Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside> */}
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="600">
                        <Link to="/services/web-development-services/php-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={drupal_logo} alt="Drupal Development" title="Drupal Development" />
                            <img width="60" height="60" className="withHoverImg" src={drupal_logo_white} alt="Drupal Development" title="Drupal Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                      <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="650">
                        <Link to="/services/web-development-services/mevn-stack-development" className="hexagonal_shape">
                          <div className="hexa-box">
                          <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={mevn_logo} alt="Mevn Development" title="Mevn Development" />
                            <img width="60" height="60" className="withHoverImg" src={mevn_white_logo} alt="Mevn Development" title="Mevn Development" />
                          </LazyLoad>
                          </div>
                        </Link>
                      </aside>
                    </li>
                  </ul>
                </div>
                
                {/* Start - Section Design For Mobile/Tab "Web Development Is Our Passion" */}
                <Grid item xs={12} className="web-mobile-icons-wrap">
                  <Slider {...webiconsmobile}>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="50">
                          <Link to="/services/web-development-services/mevn-stack-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={node_js} alt="NodeJs Development" title="NodeJs Development" /> 
                              <img width="60" height="60" className="withHoverImg" src={node_js_white} alt="NodeJs Development" title="NodeJs Development" />  
                            </LazyLoad>                    
                            </div>
                          </Link>
                        </aside>
                      </div>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="50">
                          <Link to="/services/web-development-services/mean-stack-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={mean_logo} alt="Mean Development" title="Mean Development" />
                              <img width="60" height="60" className="withHoverImg" src={mean_white_logo} alt="Mean Development" title="Mean Development" />    
                            </LazyLoad>                   
                            </div>
                          </Link>
                        </aside>
                      </div>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="100">
                          <Link to="/services/app-development-services/ios-app-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={apple_logo} alt="iOS Development" title="iOS Development" />  
                              <img width="60" height="60" className="withHoverImg" src={apple_logo_white} alt="iOS Development" title="iOS Development" />  
                            </LazyLoad>                    
                            </div>
                          </Link>
                        </aside>
                      </div>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="150">
                        <Link to="/services/app-development-services/android-app-development" className="hexagonal_shape">
                          <div className="hexa-box">
                            <LazyLoad once offset={300}>
                            <img width="60" height="60" className="withoutHoverImg" src={android_logo} alt="Android Development" title="Android Development" /> 
                            <img width="60" height="60" className="withHoverImg" src={android_logo_white} alt="Android Development" title="Android Development" />  
                          </LazyLoad>                    
                          </div>
                        </Link>
                      </aside>
                      </div>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="200">
                          <Link to="/services/web-development-services/python-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={python_logo} alt="Python Development" title="Python Development" /> 
                              <img width="60" height="60" className="withHoverImg" src={python_logo_white} alt="Python Development" title="Python Development" />   
                            </LazyLoad>                   
                            </div>
                          </Link>
                        </aside>
                      </div>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="250">
                          <Link to="/services/web-development-services/php-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={php_logo} alt="PHP Development" title="PHP Development" /> 
                              <img width="60" height="60" className="withHoverImg" src={php_white_logo} alt="PHP Development" title="PHP Development" />    
                            </LazyLoad>                  
                            </div>
                          </Link>
                        </aside>
                      </div>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="300">
                          <Link to="/services/app-development-services/react-native-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={Reactjs_logo} alt="React Native Development" title="React Native Development" />
                              <img width="60" height="60" className="withHoverImg" src={Reactjs_logo_white} alt="React Native Development" title="React Native Development" />       
                            </LazyLoad>               
                            </div>
                          </Link>
                        </aside>
                      </div>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="350">
                          <Link to="/services/web-development-services/mern-stack-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={mern_logo} alt="Mern Development" title="Mern Development" />
                              <img width="60" height="60" className="withHoverImg" src={mern_white_logo} alt="Mern Development" title="Mern Development" />      
                            </LazyLoad>                 
                            </div>
                          </Link>
                        </aside>
                      </div>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="400">
                          <Link to="/services/web-development-services/mean-stack-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={angular_logo} alt="Angular Development" title="Angular Development" /> 
                              <img width="60" height="60" className="withHoverImg" src={angular_logo_white} alt="Angular Development" title="Angular Development" />   
                            </LazyLoad>                
                            </div>
                          </Link>
                        </aside>
                      </div>
                      {/* <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="450">
                          <Link to="/services/web-development-services/asp-net-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={microsoft_logo} alt="ASP.NET Development" title="ASP.NET Development" />
                              <img width="60" height="60" className="withHoverImg" src={microsoft_logo_white} alt="ASP.NET Development" title="ASP.NET Development" />             
                            </LazyLoad>         
                            </div>
                          </Link>
                        </aside>
                      </div> */}
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="500">
                          <Link to="/services/web-development-services/php-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={Wordpress_Blue_logo} alt="Wordpress Development" title="Wordpress Development" />
                              <img width="60" height="60" className="withHoverImg" src={Wordpress_Blue_logo_white} alt="Wordpress Development" title="Wordpress Development" />   
                            </LazyLoad>                  
                            </div>
                          </Link>
                        </aside>
                      </div>
                      {/* <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="550">
                          <Link to="/services/ecommerce-development-services/magento-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={magento_logo} alt="Magento Development" title="Magento Development" /> 
                              <img width="60" height="60" className="withHoverImg" src={magento_logo_white} alt="Magento Development" title="Magento Development" />  
                            </LazyLoad>                    
                            </div>
                          </Link>
                        </aside>
                      </div> */}
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="600">
                          <Link to="/services/web-development-services/php-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={drupal_logo} alt="Drupal Development" title="Drupal Development" />
                              <img width="60" height="60" className="withHoverImg" src={drupal_logo_white} alt="Drupal Development" title="Drupal Development" />   
                            </LazyLoad>                   
                            </div>
                          </Link>
                        </aside>
                      </div>
                      <div>
                        <aside data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="650">
                          <Link to="/services/web-development-services/mevn-stack-development" className="hexagonal_shape">
                            <div className="hexa-box">
                            <LazyLoad once offset={300}>
                              <img width="60" height="60" className="withoutHoverImg" src={mevn_logo} alt="Mevn Development" title="Mevn Development" />
                              <img width="60" height="60" className="withHoverImg" src={mevn_white_logo} alt="Mevn Development" title="Mevn Development" />    
                            </LazyLoad>                  
                            </div>
                          </Link>
                        </aside>
                      </div>
                  </Slider>
                </Grid>
                {/* End - Section Design For Mobile/Tab "Web Development Is Our Passion" */}

              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Web Development Is Our Passion" */}
          
          <Suspense fallback={<div></div>}>           
          {/* Start - OurClients Import From Common Components */}
          <OurClients />
          {/* End - OurClients Import From Common Components */}

          {/* Start - HomePortfolioSlider Import From Common Components */}
          <HomePortfolioSlider />
          {/* End - HomePortfolioSlider Import From Common Components */}  
          
          {/* Start - NumberCount Import From Common Components */}  
          <NumberCount />
          {/* End - NumberCount Import From Common Components */} 
          
          {/* Start - DiscussYourProject Import From Common Components */} 
          <DiscussYourProject />
          {/* End - DiscussYourProject Import From Common Components */} 
          </Suspense>

          {/* Start - Section Design "Contented Customers Of Visions" */}           
          <Grid item xs={12}>
            <Grid component="div" className="testimonials_2_layout sec_padding" data-aos="fade-up" data-aos-duration="1500">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title">
                    WHAT OUR CLIENTS SAY ABOUT US
                  </Typography>
                  <Typography className="text-center">
                    Client Satisfaction is our first priority.
                  </Typography>
                </Grid>
                <Grid item xs={12} className="testimonials_wrapping">
                  <Slider {...settingstestimonialslider}>
                    <div className="slide_item testimonial_list">
                      <aside>
                        <div className="user_img">
                          <div className="img_wrap">
                            <LazyLoad once offset={300}><img src={Spencer_Sharpe} alt="Spencer Sharpe" title="Spencer Sharpe" width="100"  height="100" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h3">Spencer Sharpe</Typography>
                        <Typography variant="h5" className="company_name">
                        iTS COOLER
                        </Typography>
                        <Typography variant="h5" className="position">
                          CEO and Founder
                        </Typography>
                        <Typography>
                        The only words I can say about the Visions team is nothing is impossible or to much trouble , I have been working with all the team and visited there offices, there work ethics, is some of the best I have ever had the privilege to work with, Working with Visions is not just a customer, supplier relationship but for being part of a family.
                        </Typography>
                      </aside>
                    </div>
                    <div className="slide_item testimonial_list">
                      <aside>
                        <div className="user_img">
                          <div className="img_wrap">
                            <LazyLoad once offset={300}><img src={Douglas_McKalip} alt="Douglas McKalip" title="Douglas McKalip" width="100"  height="100" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h3">Douglas McKalip</Typography>
                        <Typography variant="h5" className="company_name">
                        PILL ID
                        </Typography>
                        <Typography variant="h5" className="position">
                          CEO and Founder
                        </Typography>
                        <Typography>
                          I have been working with the good people over at Visions for several years now and I would not work with anyone else for any sort of Android / iOS / PHP / Web / graphic design/development work. They always have answers to the toughest of questions I have asked. Their depth of experience keeps me out of trouble time and time again.They are very good at what they do, trustworthy and affordable. (Thanks guys! 5 Stars!)
                        </Typography>
                      </aside>
                    </div>
                    {/* <div className="slide_item testimonial_list">
                      <aside>
                        <div className="user_img">
                          <div className="img_wrap">
                            <LazyLoad once offset={300}><img src={Carl_Dennie} alt="Carl Dennie" title="Carl Dennie" width="100"  height="100" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h3">Carl Dennie</Typography>
                        <Typography variant="h5" className="company_name">
                        Would Love to Go
                        </Typography>
                        <Typography variant="h5" className="position">
                          CEO and Founder
                        </Typography>
                        <Typography>
                          We have been working with Visions for the past few years. They are very experienced developers and do exceptional work to a high standard. They always go out of their way to keep us happy and have a fantastic work ethic. We highly recommend them.
                        </Typography>
                      </aside>
                    </div> */}
                    <div className="slide_item testimonial_list">
                      <aside>
                        <div className="user_img">
                          <div className="img_wrap">
                            <LazyLoad once offset={300}><img src={Ante_Kordic} alt="Ante Kordic" title="Ante Kordic" width="100"  height="100" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h3">Ante Kordic</Typography>
                        <Typography variant="h5" className="company_name">
                        Gymbers
                        </Typography>
                        <Typography variant="h5" className="position">
                          CEO and Founder
                        </Typography>
                        <Typography>
                        I worked with their team now for a long time, very responsible and skilled people. It is affordable to work with them, and the quality of work is just amazing. I think there is nothing they can't do. I am happy that I found them, and have the opportunity to work with them!
                        </Typography>
                      </aside>
                    </div>
                    <div className="slide_item testimonial_list">
                      <aside>
                        <div className="user_img">
                          <div className="img_wrap">
                            <LazyLoad once offset={300}><img src={Dylan_Marriott} alt="Dylan Marriott" title="Dylan Marriott" width="100"  height="100" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h3">Dylan Marriott</Typography>
                        <Typography variant="h5" className="company_name">
                        Gorilla VPN
                        </Typography>
                        <Typography variant="h5" className="position">
                          CEO and Founder
                        </Typography>
                        <Typography>
                          I've been working on a project for over a year along side Visions and they've prepared everything exactly how we wanted it.They've got amazing communication, quick delivery and very easy to work with, wouldn't pick anyone else and the price is more than reasonable!
                        </Typography>
                      </aside>
                    </div>
                    <div className="slide_item testimonial_list">
                      <aside>
                        <div className="user_img">
                          <div className="img_wrap">
                            <LazyLoad once offset={300}><img src={Narelle_Bettini} alt="Narelle Bettini" title="Narelle Bettini" width="100"  height="100" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h3">Narelle Bettini</Typography>
                        <Typography variant="h5" className="company_name">
                        The Inner circle
                        </Typography>
                        <Typography variant="h5" className="position">
                          CEO and Founder
                        </Typography>
                        <Typography>
                        Visions work was a very high standard and the logo turned out just as we had imagined. Very helpful with all areas of getting the job out. Helped me out with technology issues and very pleased with end result.
                        </Typography>
                      </aside>
                    </div>
                    <div className="slide_item testimonial_list">
                      <aside>
                        <div className="user_img">
                          <div className="img_wrap">
                            <LazyLoad once offset={300}><img src={Don_Cherry} alt="Don Cherry" title="Don Cherry" width="100"  height="100" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h3">Don Cherry</Typography>
                        <Typography variant="h5" className="company_name">
                        Crafty Canine Club
                        </Typography>
                        <Typography variant="h5" className="position">
                          CEO and Founder
                        </Typography>
                        <Typography>
                        Visions is an absolute PLEASURE to work with. They are so attentive to all your needs and makes sure to listen carefully to adhere to your vision. It was so easy to work with them as they are great communicator and always sticks to their schedule. VISIONS is a great company and with expert level web designers!
                        </Typography>
                      </aside>
                    </div>
                    <div className="slide_item testimonial_list">
                      <aside>
                        <div className="user_img">
                          <div className="img_wrap">
                            <LazyLoad once offset={300}><img src={tony_knutsen} alt="Tony Knutsen" title="Tony Knutsen" width="100"  height="100" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h3">Tony Knutsen</Typography>
                        <Typography variant="h5" className="company_name">
                         TK Inspections Hull Ltd
                        </Typography>
                        <Typography variant="h5" className="position">
                          CEO and Founder
                        </Typography>
                        <Typography>
                          Visions always is very good and answers all questions quickly. Quick work, no issues, will use again. Many thanks, Visions for a job done quickly with no fuss. All amendments are very quick in deed. We wouldn't go anywhere else.
                        </Typography>
                      </aside>
                    </div>
                  </Slider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Contented Customers Of Visions" */}  

        </Grid>
      </main>
    );
  }
}

export default HomePage;
